import { useEffect, useState } from 'react';
import {
  FEATURE_FLAGS_LOADED_EVENT_NAME,
  featureFlagsLoadedValidation,
} from '../utils/featureFlags.utils';

const useFeatureFlagLoadedValidation = () => {
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);

  const onFeatureFlagsLoaded = () => {
    setFeatureFlagsLoaded(featureFlagsLoadedValidation());
  };

  useEffect(() => {
    window.addEventListener(
      FEATURE_FLAGS_LOADED_EVENT_NAME,
      onFeatureFlagsLoaded,
    );

    return () => window.removeEventListener(
      FEATURE_FLAGS_LOADED_EVENT_NAME,
      onFeatureFlagsLoaded,
      true,
    );
  }, []);

  return { featureFlagsLoaded };
};

export { useFeatureFlagLoadedValidation };
