import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Message } from '../common/Message';
import { usePartnersData } from '../../partners/usePartnersData';
import { QUOTE_MAX_SIZE } from '../../constants';

const QuoteSizeWarning = () => {
  const { t } = useTranslation('common');
  const store = useSelector(({ quoting }) => quoting);
  const { supportPhoneNumber } = usePartnersData();
  const { data } = store;

  const show = useMemo(() => data
    && data?.petQuoteResponseList.length >= QUOTE_MAX_SIZE, [data]);

  return (
    <Message
      message={t('quoteSizeWarning', {
        petsLimit: QUOTE_MAX_SIZE,
        phoneNumber: supportPhoneNumber,
      })}
      show={show}
      type="info"
    />
  );
};

export { QuoteSizeWarning };
