import React, { useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import dogIconActive from '../../assets/dog-selected.svg';
import catIconActive from '../../assets/cat-selected.svg';
import loadingSpinner from '../../assets/loading-spinner.json';
import doneSpinner from '../../assets/loading-check-white.json';

import { PetType } from '../../constants';
import { useOnEscEvent, useRedesign, useSavedElement } from '../../hooks';
import { saveSelectedRemovePetModal, removePet } from '../../actions';
import { forceFocusElement } from '../../util';

import FigoLottie from '../common/FigoLottie';
import './ModalRemovePet.css';

const ModalRemovePet = () => {
  const store = useSelector((stores) => stores.quoting);
  const {
    data,
    newCustomer: { lastName, name },
    parameters,
    petQuoteSelected,
    petRemovedLoading,
    petRemovedSuccess,
    petToRemove,
    sessionInformation: { nopCommerceUser, userEmail },
  } = store;
  const { petName, petType } = petToRemove;
  const dispatch = useDispatch();
  const { goToSavedElementAndForgot } = useSavedElement();
  const isRedesign = useRedesign();

  const fullName = useMemo(() => {
    if (nopCommerceUser.FirstName) {
      return `${nopCommerceUser.FirstName} ${nopCommerceUser.LastName}`;
    }

    return `${name} ${lastName}`;
  }, [
    lastName,
    name,
    nopCommerceUser.FirstName,
    nopCommerceUser.LastName,
  ]);

  const description = `Are you sure you want to remove 
    ${petName} from your insurance quote?`;

  const focusDropdown = (newQuoteId) => () => {
    forceFocusElement(isRedesign
      ? `pet-${newQuoteId}`
      : `petQuoteDropDownItem${newQuoteId}-header`);
  };

  const handleRemovePetClick = () => {
    if (petRemovedLoading) {
      return;
    }

    const petQuoteList = data.petQuoteResponseList
      .filter((element) => element.petQuoteId !== petQuoteSelected);
    const newPetQuoteSelected = petQuoteList[
      petQuoteList.length - 1].petQuoteId;
    const newQuoteSelectedValues = store.quoteSelectedValues
      .filter((element) => element.quoteId !== petQuoteSelected);

    const petSelected = petQuoteList.find((pet) => (
      pet.petQuoteId === newPetQuoteSelected));

    dispatch(removePet({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      effectiveDateCustom: store.effectiveDateCustom,
      eMail: userEmail || parameters.email,
      employerName: fullName,
      modifiers: petSelected.modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList,
      promoCode: store.newPet.promoCode,
      quoteId: newPetQuoteSelected,
      quoteSelectedValues: newQuoteSelectedValues,
      removePetIds: [petQuoteSelected],
      toggleOpen: focusDropdown(newPetQuoteSelected),
    }));
  };

  const handleCancelRemovePetClick = useCallback(() => {
    dispatch(saveSelectedRemovePetModal(false));
    setTimeout(goToSavedElementAndForgot, 0.01);
  }, [dispatch, goToSavedElementAndForgot]);

  useOnEscEvent(() => {
    handleCancelRemovePetClick();
  }, []);

  function renderContentButton() {
    if (!petRemovedLoading && !petRemovedSuccess) {
      return 'Remove';
    }

    const animationData = petRemovedLoading ? loadingSpinner : doneSpinner;

    return (
      <FigoLottie
        animationData={animationData}
        height={30}
        width={30}
      />
    );
  }

  return (
    <div className="Modal-remove-pet-container">
      <img
        alt=""
        aria-hidden="true"
        className="Modal-remove-pet-image"
        src={petType === PetType.Dog.value
          ? dogIconActive : catIconActive}
      />

      <h4 className="Modal-remove-pet-title">Remove Pet</h4>

      <span className="Modal-remove-pet-description">{description}</span>

      <div className="Modal-remove-footer-buttons-container">
        <input
          aria-label="Cancel"
          className={'Modal-remove-footer-button '
            + 'Modal-remove-footer-cancel-button'}
          disabled={petRemovedLoading}
          onClick={handleCancelRemovePetClick}
          title="Cancel"
          type="button"
          value="Cancel"
        />

        <button
          className={'Modal-remove-footer-button '
            + 'Modal-remove-footer-remove-button'}
          onClick={handleRemovePetClick}
          type="button"
        >
          {renderContentButton()}
        </button>
      </div>
    </div>
  );
};

export default ModalRemovePet;
