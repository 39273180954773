/** @deprecated Data does not match with real zipcodes.
 * TODO: Remove current references
 */
export function getStateAbbreviation(zipString) {
  if (typeof zipString !== 'string' || zipString.length !== 5) {
    return '';
  }

  const zipcode = parseInt(zipString, 10);

  const states = [{ code: 'AL', max: 36999, min: 35000 },
    { code: 'AK', max: 99999, min: 99500 },
    { code: 'AZ', max: 86999, min: 85000 },
    { code: 'AR', max: 72999, min: 71600 },
    { code: 'CA', max: 96699, min: 90000 },
    { code: 'CO', max: 81999, min: 80000 },
    { code: 'CT', max: 6999, min: 6000 },
    { code: 'DE', max: 19999, min: 19700 },
    { code: 'FL', max: 34999, min: 32000 },
    { code: 'GA', max: 31999, min: 30000 },
    { code: 'HI', max: 96999, min: 96700 },
    { code: 'ID', max: 83999, min: 83200 },
    { code: 'IL', max: 62999, min: 60000 },
    { code: 'IN', max: 47999, min: 46000 },
    { code: 'IA', max: 52999, min: 50000 },
    { code: 'KS', max: 67999, min: 66000 },
    { code: 'KY', max: 42999, min: 40000 },
    { code: 'LA', max: 71599, min: 70000 },
    { code: 'ME', max: 4999, min: 3900 },
    { code: 'MD', max: 21999, min: 20600 },
    { code: 'MA', max: 2799, min: 1000 },
    { code: 'MI', max: 49999, min: 48000 },
    { code: 'MN', max: 56999, min: 55000 },
    { code: 'MS', max: 39999, min: 38600 },
    { code: 'MO', max: 65999, min: 63000 },
    { code: 'MT', max: 59999, min: 59000 },
    { code: 'NC', max: 28999, min: 27000 },
    { code: 'ND', max: 58999, min: 58000 },
    { code: 'NE', max: 69999, min: 68000 },
    { code: 'NV', max: 89999, min: 88900 },
    { code: 'NH', max: 3899, min: 3000 },
    { code: 'NJ', max: 8999, min: 7000 },
    { code: 'NM', max: 88499, min: 87000 },
    { code: 'NY', max: 14999, min: 10000 },
    { code: 'OH', max: 45999, min: 43000 },
    { code: 'OK', max: 74999, min: 73000 },
    { code: 'OR', max: 97999, min: 97000 },
    { code: 'PA', max: 19699, min: 15000 },
    { code: 'PR', max: 999, min: 300 },
    { code: 'RI', max: 2999, min: 2800 },
    { code: 'SC', max: 29999, min: 29000 },
    { code: 'SD', max: 57999, min: 57000 },
    { code: 'TN', max: 38599, min: 37000 },
    { code: 'TX', max: 79999, min: 75000 },
    { code: 'TX', max: 88599, min: 88500 },
    { code: 'UT', max: 84999, min: 84000 },
    { code: 'VT', max: 5999, min: 5000 },
    { code: 'VA', max: 24699, min: 22000 },
    { code: 'DC', max: 20599, min: 20000 },
    { code: 'WA', max: 99499, min: 98000 },
    { code: 'WV', max: 26999, min: 24700 },
    { code: 'WI', max: 54999, min: 53000 },
    { code: 'WY', max: 83199, min: 82000 }];

  const state = states.find((s) => s.min <= zipcode && s.max >= zipcode);

  let zipcodeAbbreviation = '';

  if (state) {
    zipcodeAbbreviation = state.code;
  }

  return zipcodeAbbreviation;
}
