import React from 'react';

import { useTranslation } from 'react-i18next';
import { UNDERWRITING_LINK } from '../../../constants';
import { usePartnersData } from '../../../partners/usePartnersData';
import './css/HeaderUnderwriting.css';

const HeaderUnderwriting = () => {
  const { t } = useTranslation('common');
  const { supportPhoneNumber } = usePartnersData();

  return (
    <div className="Header-underwriting">
      <span>
        <span className="Header-underwriting-call">{t('header.call')}</span>

        <a href={`tel:${supportPhoneNumber}`}>
          {supportPhoneNumber}
        </a>
      </span>

      |

      <a href={UNDERWRITING_LINK} rel="noopener noreferrer" target="_blank">
        {t('header.underwriting')}
      </a>
    </div>
  );
};

export { HeaderUnderwriting };
