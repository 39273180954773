import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  CostcoPartnerDiscountLabel,
} from '../components/quoting/CostcoPartnerDiscountLabel';
import { useCostcoTN, useGoodDogTN } from '../util';
import {
  GenericPartnerDiscountLabel,
} from '../components/quoting/GenericPartnerDiscountLabel';
import { useGoodDogData } from './useGoodDogData';
import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../constants';

export const usePartnersData = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const goodDogData = useGoodDogData();
  const {
    data,
    isCostco,
    isGoodDog,
    isUSAA,
    loading,
    partnerId,
  } = store?.partners;

  const useTennesseeValidations = useMemo(() => {
    if (isCostco) {
      return useCostcoTN;
    }

    if (isGoodDog) {
      return useGoodDogTN;
    }

    return false;
  }, [isCostco, isGoodDog]);

  const renderCardPlanHeader = useCallback(() => {
    if (isCostco) {
      return (
        <div className="Card-plan-partner-label-container">
          <CostcoPartnerDiscountLabel discountLabel={data.DiscountLabel} />
        </div>
      );
    }

    if (data?.Logo || data?.DiscountLabel) {
      return (
        <div className="Card-plan-partner-label-container">
          <GenericPartnerDiscountLabel
            brandName={data?.PartnerDisplayName}
            label={data?.DiscountLabel}
            logo={data.Logo}
          />
        </div>
      );
    }

    return null;
  }, [data, isCostco]);

  const supportPhoneNumber = useMemo(() => {
    if (data && data?.SupportPhoneNumber) {
      return data.SupportPhoneNumber;
    }
    return CUSTOMER_SERVICE_PHONE_NUMBER;
  }, [data]);

  return {
    data,
    goodDogData,
    isCostco,
    isGoodDog,
    isPartner: !!partnerId,
    isUSAA,
    loading,
    renderCardPlanHeader,
    supportPhoneNumber,
    useTennesseeValidations,
  };
};
