import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';

import {
  closeSaveQuoteModalConfirmation,
  forgetPasswordVisibleChanged,
  loadMultiModalData,
  newCustomerChanged,
  noDiamondClientIdError,
  saveSelectedAddPetModal,
  saveSelectedEditPetModal,
} from '../../../actions';

import { YourInfoContent } from '../yourInfo/YourInfoContent';
import ModalContainer from '../../common/ModalContainer';
import { ModalAddPet } from '../../common/modalAddPet/ModalAddPet';
import ModalRemovePet from '../ModalRemovePet';
import MultiModalInfo from '../../common/MultiModalInfo';
import { SaveYourQuoteModal } from '../SaveYourQuoteModal';
import SidebarSaveQuoteModalConfirmation
from '../SidebarSaveQuoteModalConfirmation';
import { ComingSoonModal } from '../../landing/ComingSoonModal';
import { PriceStickyBar } from '../purchase/PriceStickyBar';
import { QuoteContent } from '../QuoteContent';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';

import {
  createEffectiveDate,
  forceFocusElement,
  useMarketingChannel,
  useROPC,
} from '../../../util';
import { MODAL_RELATION_LIST, QuotingSteps } from '../../../constants';
import ModalCustomerWelcome from '../../common/ModalCustomerWelcome';
import ModalCustomerLogin from '../../common/ModalCustomerLogin';
import { dataLayerViewItem } from '../../../gtmDataLayer';

import './css/QuotingHeader.css';
import ModalForgetPassword from '../../common/ModalForgetPassword';
import { NEW_CUSTOMER_EMAIL_CHANGED } from '../../../actions/types';
import { DuplicatedPurchasesModal } from '../purchase/duplicatedPurchasesModal';
import { Header } from './Header';
import { useRedesign } from '../../../hooks';

function QuotingHeader() {
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const isRedesign = useRedesign();
  const parametersRaw = queryString.parse(location.search);
  const store = useSelector(({ quoting }) => quoting);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const {
    addPetModalVisible,
    currentQuoteStep,
    customerZipCode,
    editPetModalVisible,
    isLoading,
    newCustomer,
    multiModalData: { ZipCode },
    multiModalUserSelection: {
      needToKnowIdSelected,
      needToKnowModalVisible,
      petCoPayIdSelected,
      petCoPayModalVisible,
      termsAndCondIdSelected,
      termsAndCondModalVisible,
      wellnessIdSelected,
      wellnessModalVisible,
    },
    loggingWithoutModal,
    removePetModalVisible,
    saveQuote: {
      savedQuoteModalVisible,
    },
    sessionInformation,
  } = store;

  const {
    isCustomer,
    loadingLogin,
    nopCommerceUser,
    showComingSoonModal,
  } = store.sessionInformation;

  const {
    NEED_TO_KNOW_MODAL_ID,
    PET_CO_PAY_MODAL_ID,
    TERMS_AND_COND_MODAL_ID,
    WELLNESS_MODAL_ID,
  } = MODAL_RELATION_LIST;

  const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;

  const styles = useMemo(() => {
    if (Object.entries(parametersRaw).length || isSecondStep) {
      return {
        bodyStyle: 'App-body-quote',
      };
    }

    return {
      bodyStyle: '',
    };
  }, [isSecondStep, parametersRaw]);

  const showLoadingOverlay = useMemo(() => isLoading
    || store.isLoadingNewAddress
    || store.purchase.loading
    || store.rateSummary.loading
    || store.sessionInformation.isLoading, [
    isLoading,
    store.isLoadingNewAddress,
    store.purchase.loading,
    store.rateSummary.loading,
    store.sessionInformation.isLoading,
  ]);

  const handleCloseModalAddPet = useCallback(() => {
    dispatch(saveSelectedEditPetModal(false));
    dispatch(saveSelectedAddPetModal(false));
    const { petQuoteSelected, data: { petQuoteResponseList } } = store;
    const currentQuote = (petQuoteResponseList || [])
      .find((quote) => quote.petQuoteId === petQuoteSelected);
    forceFocusElement(isRedesign
      ? `pet-${currentQuote.petQuoteId}`
      : `petQuoteDropDownItem${currentQuote.petQuoteId}-header`);
  }, [dispatch, isRedesign, store]);

  const handleOnClickCloseConfirmationModal = () => {
    dispatch(closeSaveQuoteModalConfirmation());
  };

  useEffect(() => {
    if (!customerZipCode || customerZipCode === ZipCode) {
      return;
    }

    dispatch(loadMultiModalData({
      effectiveDate: createEffectiveDate(),
      isEBProduct: false,
      zipCode: customerZipCode,
    }));
  }, [customerZipCode, dispatch, ZipCode]);

  // GTM
  useEffect(() => {
    dataLayerViewItem({
      pathname: location.pathname,
      petIndex: store.petQuoteSelected,
    });
  }, [location.pathname, store.petQuoteSelected]);

  const onModalClosed = useCallback(() => {
    setShowModalLogin(true);
  }, []);

  useEffect(() => {
    if (useMarketingChannel) {
      return;
    }

    // Free user don't have DiamondClientId only CustomerId
    const isFreeUser = sessionInformation.isCustomer
      && !nopCommerceUser.DiamonClientdId
      && nopCommerceUser.CustomerNopCommerceId;
    const isLoadingSessionInformation = sessionInformation.isLoading
      || sessionInformation.loadingLogin;

    if (!isSecondStep
      || !isFreeUser
      || isLoadingSessionInformation) {
      return;
    }

    dispatch(noDiamondClientIdError('User without diamondId.'));
  }, [
    isSecondStep,
    dispatch,
    sessionInformation,
    nopCommerceUser.DiamonClientdId,
    nopCommerceUser.CustomerNopCommerceId,
  ]);

  useEffect(() => {
    if (newCustomer.email && isCustomer && !nopCommerceUser.LoginSuccess
      && (isSecondStep || (!isSecondStep && !loggingWithoutModal))) {
      setShowModalLogin(true);
      dispatch(forgetPasswordVisibleChanged({ visible: false }));
    }

    if (nopCommerceUser.LoginSuccess) {
      setShowModalLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isCustomer, newCustomer.email, nopCommerceUser]);

  useEffect(() => {
    setShowModalLogin(false);
  }, [showComingSoonModal]);

  const onDismissLoginModal = useCallback(() => {
    if (!loadingLogin) {
      dispatch(newCustomerChanged({
        type:
          NEW_CUSTOMER_EMAIL_CHANGED,
        value: '',
      }));
      setShowModalLogin(false);
    }
  }, [dispatch, loadingLogin]);

  const handleForgetPasswordClick = useCallback(() => {
    if (!loadingLogin) {
      setShowModalLogin(false);
      dispatch(forgetPasswordVisibleChanged({ visible: true }));
    }
  }, [dispatch, loadingLogin]);

  return (
    <>
      <Header />

      <main className={`App-body ${styles.bodyStyle}`}>
        <Switch>
          <Route exact path={match.path}>
            <QuoteContent />
          </Route>

          <Route path={`${match.path}/yourInfo/:scrollToTop?`}>
            <YourInfoContent showLoadingOverlay={showLoadingOverlay} />
          </Route>
        </Switch>
      </main>

      <PriceStickyBar />

      <DuplicatedPurchasesModal />

      <ModalContainer
        cancelable={false}
        show={addPetModalVisible || editPetModalVisible}
      >
        <ModalAddPet
          handleCloseModalAddPet={handleCloseModalAddPet}
          isEditing={editPetModalVisible}
          show={addPetModalVisible || editPetModalVisible}
        />
      </ModalContainer>

      {useROPC ? (
        <ModalContainer
          cancelable={false}
          handleOutClick={onDismissLoginModal}
          show={!showLoadingOverlay && showModalLogin
            && !showComingSoonModal}
        >
          <ModalCustomerLogin
            emailUser={newCustomer.email}
            onDismiss={onDismissLoginModal}
            onForgotPassword={handleForgetPasswordClick}
          />
        </ModalContainer>
      ) : null}

      <ModalCustomerWelcome isSecondStep={isSecondStep} />

      <ModalForgetPassword onModalClosed={onModalClosed} />

      <ComingSoonModal />

      <ModalContainer
        cancelable={false}
        show={removePetModalVisible}
      >
        <ModalRemovePet />
      </ModalContainer>

      <MultiModalInfo
        menuSelected={needToKnowIdSelected}
        modalId={NEED_TO_KNOW_MODAL_ID}
        show={needToKnowModalVisible}
      />

      <MultiModalInfo
        menuSelected={termsAndCondIdSelected}
        modalId={TERMS_AND_COND_MODAL_ID}
        show={termsAndCondModalVisible}
      />

      <MultiModalInfo
        menuSelected={wellnessIdSelected}
        modalId={WELLNESS_MODAL_ID}
        show={wellnessModalVisible}
      />

      {/* FPA-11163 */}
      <MultiModalInfo
        menuSelected={petCoPayIdSelected}
        modalId={PET_CO_PAY_MODAL_ID}
        show={petCoPayModalVisible}
      />

      <MultiModalInfo
        menuSelected={-1}
        show={store.multiModalCustomData}
      />

      <SaveYourQuoteModal />

      <SidebarSaveQuoteModalConfirmation
        handleOnClick={handleOnClickCloseConfirmationModal}
        show={savedQuoteModalVisible}
      />

      <FigoLoadingOverlay visible={showLoadingOverlay} />

      {/* OneInc modal create here */}
      <div id="portalOneContainer" />
    </>
  );
}

export { QuotingHeader };
