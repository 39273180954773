import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import infoIcon from '../../assets/info.png';

import './TooltipDiscount.css';

const TooltipDiscount = () => {
  const { t } = useTranslation('quoting');
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleHoverIn = useCallback(() => {
    setTooltipVisible(true);
  }, []);

  const handleHoverOut = useCallback(() => {
    setTooltipVisible(false);
  }, []);

  const handleClick = useCallback(() => {
    setTooltipVisible((prev) => !prev);
  }, []);

  const contentContent = () => {
    if (!tooltipVisible) {
      return null;
    }

    return (
      <div className="Tooltip-discount-overlayed-container">
        <span>
          {t('costco.tooltip')}
        </span>

        <div className="Tooltip-discount-arrow-down" />
      </div>
    );
  };

  return (
    <div className={tooltipVisible ? 'Tooltip-discount-main-container' : ''}>
      <button
        className="Tooltip-discount-icon-container"
        onBlur={handleHoverOut}
        onClick={handleClick}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
        type="button"
      >
        <img alt={t('costco.discountInfo')} src={infoIcon} />
      </button>

      <div aria-live="polite">
        {contentContent()}
      </div>
    </div>
  );
};

export { TooltipDiscount };
