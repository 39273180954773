import React, { useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  hideCustomerWelcomeMessage,
  modalCustomerWelcomeVisibleChanged,
  reRateByNopCommerCustomer,
  selectedPlan,
  setPowerUpErrors,
  validateAllPetsPowerups,
} from '../../actions';
import syncIcon from '../../assets/sync-clouds-citron.svg';
import { forceFocusElement, validatePowerupsSelected } from '../../util';
import ModalContainer from './ModalContainer';
import './ModalCustomerWelcome.css';
import { CUSTOM_PLAN_ID } from '../quoting/planSection/constants';
import { useCallbackAfterRate, useRedesign } from '../../hooks';
import { AFTER_RATE_TAG } from '../../constants';

let isMounted = false;
const ModalCustomerWelcome = ({ goTo, isSecondStep = false }) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const location = useLocation();
  const locationParams = new URLSearchParams(location?.search || '');
  const { t } = useTranslation('quoting');

  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    modalCustomerWelcomeVisible,
    parameters,
    petQuoteSelected,
    quoteSelectedValues,
    sessionInformation,
    subId,
  } = store;
  const { nopCommerceUser, userEmail } = sessionInformation;
  const buttonRef = useRef(null);
  const isRedesign = useRedesign();
  const ESSENTIAL_PLAN = store.plans[0];
  const PREFERRED_PLAN = store.plans[1];
  const ULTIMATE_PLAN = store.plans[2];

  const isNewZipcode = useMemo(
    () => (parameters.petZipCode !== nopCommerceUser.Address?.ZipCode),
    [parameters.petZipCode, nopCommerceUser.Address],
  );

  useEffect(() => {
    if (!isMounted) {
      isMounted = true;
    }

    return () => dispatch(hideCustomerWelcomeMessage());
  }, [dispatch]);

  useEffect(() => {
    if (!isMounted || !nopCommerceUser.LoginSuccess) {
      return;
    }

    if (sessionInformation.showWelcomeMessage || isNewZipcode) {
      dispatch(modalCustomerWelcomeVisibleChanged(true));
    }
  }, [
    dispatch,
    isNewZipcode,
    nopCommerceUser.LoginSuccess,
    sessionInformation,
  ]);

  useEffect(() => {
    if (modalCustomerWelcomeVisible && !isLoading && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [isLoading, modalCustomerWelcomeVisible]);

  function handleCloseFocus() {
    const quoteSelected = quoteSelectedValues
      .find((item) => item.quoteId === petQuoteSelected)
      || { petName: 'Pet Name' };

    switch (quoteSelected.selectedPlanIndex) {
      case ESSENTIAL_PLAN.index:
        forceFocusElement(`ESSENTIAL${ESSENTIAL_PLAN.index}`);
        break;

      case PREFERRED_PLAN.index:
        forceFocusElement(`PREFERRED${PREFERRED_PLAN.index}`);
        break;

      case ULTIMATE_PLAN.index:
        forceFocusElement(`ULTIMATE${ULTIMATE_PLAN.index}`);
        break;

      default:
        forceFocusElement(`PREFERRED${PREFERRED_PLAN.index}`);
        break;
    }
  }

  useCallbackAfterRate(() => {
    if (isSecondStep) {
      const petQuoteRateItem = data.petQuoteResponseList
        .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
      const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
      if (!powerUpValidation.isValid) {
        dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
        if (isRedesign) {
          window.scrollTo(0, 0);
        }
      }
    }
  }, [AFTER_RATE_TAG.newZipCode]);

  const onClickGotIt = async () => {
    const { FirstName, LastName } = nopCommerceUser;
    dispatch(modalCustomerWelcomeVisibleChanged(false));

    await dispatch(reRateByNopCommerCustomer({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      effectiveDateCustom: store.effectiveDateCustom,
      eMail: userEmail || parameters.email,
      employerName: `${FirstName} ${LastName}`,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteResponseList: data.petQuoteResponseList,
      promoCode: store.newPet.promoCode,
      subId: subId && subId.Name && subId.Value ? subId : {},
      zipCode: nopCommerceUser.Address.ZipCode,
    }));
    dispatch(validateAllPetsPowerups());

    // TODO: This is a workaround for an issue on backend
    // that should be fixed on their side but will take some time
    if (locationParams.get('isCostco')) {
      const quoteSelected = quoteSelectedValues
        .find((item) => item.quoteId === petQuoteSelected)
        || { petName: 'Pet Name' };
      dispatch(selectedPlan({
        planAmount: quoteSelected.selectedPlanAmount,
        planId: quoteSelected.selectedPlan,
        planPackagedId: CUSTOM_PLAN_ID,
      }));
    }

    handleCloseFocus();

    if (goTo) {
      goTo();
    }
  };

  function renderContent() {
    if (!isNewZipcode) {
      return (
        <p>
          {t('welcomeModal.currentZipcode')}
        </p>
      );
    }

    return (
      <p>
        {t('welcomeModal.newZipcode', {
          zipCode: nopCommerceUser.Address.ZipCode,
        })}
      </p>
    );
  }

  return (
    <ModalContainer
      cancelable={false}
      show={modalCustomerWelcomeVisible && !isLoading}
    >
      <div className="Flex Column Modal-customer-welcome-container">
        <img
          alt=""
          className="Modal-login-user-subscription-bubbles-image"
          src={syncIcon}
        />

        <h1 className="Modal-login-user-subscription-title">
          {t('welcomeModal.welcomeBack')}
        </h1>

        {renderContent()}

        <button
          ref={buttonRef}
          className="Modal-customer-welcome-got-it"
          onClick={onClickGotIt}
          type="button"
        >
          <span className="Modal-customer-got-it-text">
            {t('welcomeModal.gotIt')}
          </span>
        </button>
      </div>
    </ModalContainer>
  );
};

export default ModalCustomerWelcome;
