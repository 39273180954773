import moment from 'moment';
import {
  ADDRESS_BY_ZIP_CODE_LOADED,
  AGE_DATA_ERROR,
  AGE_DATA_LOADED,
  AMERICAN_STATES_LOADED,
  BREED_DATA_ERROR,
  BREED_DATA_LOADED,
  CONTINUE_WITHOUT_LOGIN,
  CREATE_DIAMOND_CLIENT_ATTEMPT,
  CREATE_DIAMOND_CLIENT_ERROR,
  CREATE_DIAMOND_CLIENT_SUCCESS,
  CURRENT_QUOTE_STEP_CHANGED,
  CUSTOM_VET_DATA_CLEARED,
  CUSTOM_VET_MODAL_OPENED,
  CUSTOM_VET_SAVED,
  CUSTOMER_VALIDATED,
  CUSTOMER_VALIDATION_ATTEMPT,
  CUSTOMER_VALIDATION_ERROR,
  CUSTOMER_VALIDATION_RESTARTED,
  DEDUCTIBLE_LEGEND_CHANGED,
  DIAMOND_CUSTOMER_LOGIN_ATTEMPT,
  DIAMOND_CUSTOMER_LOGIN_CLEANED,
  DIAMOND_CUSTOMER_LOGIN_FAILED,
  DIAMOND_CUSTOMER_LOGIN_SUCCESS,
  DIAMOND_CUSTOMER_LOGOUT,
  DIAMOND_CUSTOMER_RESETED_LOGIN_FAIL,
  DIAMOND_CUSTOMER_VALIDATION_ATTEMPT,
  DIAMOND_CUSTOMER_VALIDATION_FAILED,
  DIAMOND_CUSTOMER_VALIDATION_LOADED,
  DIAMOND_CUSTOMER_ZIPCODE_CHANGED,
  EFFECTIVE_DATE_CUSTOM_CHANGED,
  ERROR_MODAL_CLOSED,
  EXTOLE_PURCHASE_COMPLETED,
  EXTOLE_UUID_SAVED,
  FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE,
  FAILED_TO_LOAD_AMERICAN_STATES,
  FAILED_TO_LOAD_VETERINARIANS,
  FETCH_MESSAGE_NO_DATA,
  FETCH_MESSAGE_SUCCESS,
  FORGET_PASSWORD_CONFIRM_MODAL_VISIBLE_CHANGED,
  FORGET_PASSWORD_MODAL_EMAIL_CHANGED,
  FORGET_PASSWORD_MODAL_EMAIL_ERROR_CHANGED,
  FORGET_PASSWORD_MODAL_VISIBLE_CHANGED,
  FORGET_PASSWORD_RESET_PASSWORD_ATTEMTPED,
  FORGET_RESET_PASSWORD_FAILED,
  FORGET_RESET_PASSWORD_SUCCEEDED,
  GOOD_DOG_COMING_SOON_MODAL_HIDED,
  GOOD_DOG_COMING_SOON_MODAL_SHOWED,
  IMPERSONATING_INFO_CHANGED,
  IN_APP_STARTED,
  IN_LOGIN_APP_STARTED,
  LOAD_MULTI_MODAL_DATA_FAILED,
  LOAD_MULTI_MODAL_DATA_SUCCESS,
  LOADING_QUOTE_DATA_FINISH,
  LOADING_QUOTE_DATA,
  MODAL_CUSTOMER_WELCOME_VISIBLE_CHANGED,
  MODAL_PET_CO_PAY_VISIBLE_CHANGED,
  MULTI_MODAL_CUSTOM_CONTENT_CHANGED,
  MULTI_MODAL_CUSTOM_CONTENT_CLEARED,
  MULTI_MODAL_NEED_TO_KNOW_VISIBLE_CHANGED,
  MULTI_MODAL_PET_CO_PAY_VISIBLE_CHANGED,
  MULTI_MODAL_TERMS_AND_COND_VISIBLE_CHANGED,
  MULTI_MODAL_WELLNESS_VISIBLE_CHANGED,
  NEW_CUSTOMER_ADDRESS_LOAD_ATTEMPTED,
  NEW_CUSTOMER_EMAIL_ERROR_CHANGED,
  NEW_PET_AGE_SAVED,
  NEW_PET_BREED_SAVED,
  NEW_PET_EMAIL_SAVED,
  NEW_PET_NAME_SAVED,
  NEW_PET_PICKUP_DATE_SAVED,
  NEW_PET_PROMO_CODE_SAVED,
  NEW_PET_SEX_SAVED,
  NEW_PET_TYPE_SAVED,
  NEW_PET_ZIPCODE_SAVED,
  NO_DIAMOND_CLIENT_ID_OR_PC_ERROR,
  NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL,
  NOTIFY_ME_BUTTON_CLICKED,
  NOTIFY_ME_EMAIL_VALUES_SAVED,
  NOTIFY_ME_FAILED,
  NOTIFY_ME_SUCCESS,
  OPEN_QUOTE_LOADED,
  OPEN_QUOTE_PENDING_PETS_LOADED,
  OPEN_SAVED_QUOTE_ATTEMPT,
  OPEN_SAVED_QUOTE_ERROR,
  OPEN_SAVED_QUOTE_EXPIRED,
  PARAMETERS_UPDATED,
  PARTNER_ID_INFORMATION_FAILED,
  PARTNER_ID_INFORMATION_LOADED,
  PARTNER_ID_LOAD_INFORMATION_ATTEMPT,
  PAYMENT_METHOD_TYPE_CHANGE,
  PAYMENT_PLAN_FREQUENCY_CHANGED,
  PET_ADDED,
  PET_QUOTE_REMOVAL_ATTEMPT,
  PET_QUOTE_REMOVAL_FAILED,
  PET_QUOTE_REMOVAL_SUCCEED,
  PET_REMOVED,
  PET_TAG_COLOR_CHANGED,
  PET_TAG_DATE_ERRORS_CHANGED,
  PET_TAG_DATE_OF_BIRTH_CHANGED,
  PET_UPDATED,
  POWER_UP_SELECTED,
  PURCHASE_ATTEMPT,
  PURCHASE_COMPLETED,
  QUOTE_DATA_LOADED_BY_STORAGE,
  QUOTE_PLAN_SELECTED,
  QUOTE_POWER_UP_CHANGED,
  QUOTE_SELECTED_VALUES_REMOVED,
  QUOTE_WELLNESS_SELECTED,
  QUOTING_PLAN_DATA_LOADED,
  RATE_QUOTE_ERROR,
  RATE_QUOTE_UPDATE_ATTEMPT,
  RATE_QUOTE_UPDATE_LOADED,
  RATE_SUMMARY_ATTEMP,
  RATE_SUMMARY_FAILED,
  RATE_SUMMARY_SUCCESS,
  RECALCULATE_LOCAL_TOTAL,
  RECALCULATE_PACKAGED_VALUES_SELECTED,
  RECAPTCHA_VALIDATE_ATTEMPT,
  RECAPTCHA_VALIDATE_FAILED,
  RECAPTCHA_VALIDATE_LOADED,
  REMOVE_PET_MODAL_VISIBLE,
  RESET_POWER_UP_ARIA_LABEL,
  RESET_QUOTES_VALUES_SELECTED,
  RETRY_PURCHASE_ERROR,
  SAVE_DEDUCTIBLE_SELECTED,
  SAVE_MONTHLY_PLAN_SELECTED,
  SAVE_QUOTE_ATTEMPT,
  SAVE_QUOTE_ERROR,
  SAVE_QUOTE_MOBILE_VISIBLE_CHANGED,
  SAVE_REIMBURSEMENT_SELECTED,
  SAVE_SELECTED_ADD_PET_MODAL,
  SAVE_SELECTED_EDIT_PET_MODAL,
  SAVE_SELECTED_PET_NUMBER,
  SAVE_UPDATED_PET,
  SAVE_URL_PARAMETERS,
  SAVE_UTM_PARAMS,
  SAVED_QUOTE_MODAL_VISIBLE,
  SAVED_QUOTE,
  SECOND_ADDRESS_CLEARED,
  SECOND_PARENT_FORM_TOGGLED,
  SEND_SMS_APPS_LINKS_ATTEMPT,
  SEND_SMS_APPS_LINKS_FAILED,
  SEND_SMS_APPS_LINKS_SUCCESS,
  SIGNED_UP_SMS_ATTEMPT,
  SIGNED_UP_SMS,
  SUB_ID_SAVED,
  TERMS_AND_CONDITION_VALIDATION_CHANGED,
  VETERINARIAN_SET,
  VETERINARIANS_LOAD_ATTEMPT,
  VETERINARIANS_LOADED,
  YOUR_INFO_SCROLL_TO_TOP_TOGGLED,
  YOUR_INFO_STEP_CHANGED,
  ZIP_CODE_CUSTOMER_UPDATED,
  ZIP_CODE_VALIDATION_ATTEMPT,
  ZIP_CODE_VALIDATION_CRASHED,
  ZIP_CODE_VALIDATION_FAILED,
  ZIP_CODE_VALIDATION_SUCCESS,
  NEW_PET_GROUP_DSCR_CODE_SAVED,
  TERMS_AND_POLICY_CHANGED,
  PURCHASE_MULTIPLE_ATTEMPT,
  PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED,
  POWERUP_ID_ERRORS_SET,
  POWERUP_ID_ERRORS_REMOVED,
  POWERUP_ID_ERRORS_RESTARTED,
  CUSTOMER_PASSWORD_SET,
  LOGIN_B2C_STARTED,
  LOGIN_B2C_FAILED,
  DUPLICATED_VALIDATION_FAILED,
  DUPLICATED_VALIDATION_MODAL_CLOSED,
  QUOTE_PLAN_SELECTED_BY_QUOTEID,
  ALL_PETS_POWERUPS_VALIDATED,
} from './types';
import {
  apiCall,
  apiCallCostco,
  createEffectiveDate,
  doNothing,
  getAgeInYears,
  getPetNameFromValue,
  serializeForUri,
  sleep,
  toBoolean,
  useCostcoTN,
  useFSCLeads,
  useGoodDogTN,
  useMarketingChannel,
  useTrackingEvents,
  validateEmail,
  validatePartnerIds,
  validatePowerupsSelected,
} from '../util';
import {
  AFTER_RATE_TAG,
  MODAL_DEFAULT_CATEGORY,
  ONE_INC_DEFAULT_TOKEN,
  ONE_INC_PAYMENT_CATEGORY,
  PAYMENT_TYPE,
  PetType,
  SMS_APP_LINKS,
  subIdSynonyms,
  POWERUP_TYPE,
  PowerUpsRelation,
  USER_ROLL_LIST,
  SAVE_QUOTE_TYPE,
  PURCHASE_MULTI_STATUS,
  PURCHASE_ERROR_TYPES,
} from '../constants';
import {
  saveOneIncPetPaymentMethod,
  savePaymentDefaultMethods,
  savePaymentMethod,
  savePaymentMethods,
} from './oneInc';
import { trackingLoadQuote } from '../trackingEvents';
import { CUSTOM_PLAN_ID } from '../components/quoting/planSection/constants';
import { dataLayerViewItem } from '../gtmDataLayer';
import {
  sendFSCLead,
  sendFSCMarketingLead,
  sendFSCPurchaseLead,
} from './leads';
import { LANDING_URL } from '../routes';
import { COSTCO_PARTNER_ID } from '../partners/partners.constants';
import { getFeatureFlagFromStorage } from '../utils/featureFlags.utils';

const {
  NEED_TO_KNOW_DEF_CATEGORY_ID,
  PET_CO_PAY_DEF_CATEGORY_ID,
  TERMS_AND_COND_DEF_CATEGORY_ID,
  WELLNESS_DEF_CATEGORY_ID,
} = MODAL_DEFAULT_CATEGORY;

export function logError({
  action,
  description = '',
  endpoint,
  exception = null,
  statusCode = '',
  request = null,
  response = null,
}) {
  return (_, getState) => {
    const state = getState();
    try {
      let status = statusCode;
      if (exception && ((exception?.name === 'TimeoutError'
        || exception?.name === 'AbortError')
        || (exception.includes && exception.includes('AbortError')))) {
        status = 504;
      }

      let requestString = '';
      let responseString = '';
      try {
        if (request) {
          requestString = JSON.stringify(request);
        }
      } catch (error) {
        requestString = `INVALID JSON REQUEST: ${error}`;
      }

      try {
        if (response) {
          responseString = JSON.stringify(response);
        }
      } catch (error) {
        responseString = `INVALID JSON RESPONSE: ${error}`;
      }

      const url = 'api/Log/QuoteSiteError';
      apiCall(url, {
        post: {
          Action: action,
          Email: state?.quoting?.newCustomer?.email
            || state?.quoting?.parameters?.email,
          Endpoint: endpoint,
          ErrorDescription: description,
          HttpStatusCode: status,
          // Not compatible with all browsers
          OperatingSystem: navigator?.userAgentData?.platform,
          PartnerGuid: state?.quoting?.partners?.partnerId,
          Request: requestString,
          Response: responseString,
          UserAgent: navigator.userAgent,
        },
      });
    } catch (e) {
      doNothing();
    }
  };
}

export function noDiamondClientIdError(message) {
  return { message, type: NO_DIAMOND_CLIENT_ID_OR_PC_ERROR };
}

function getBreedDataFilteredByType(breedData, petType) {
  const breedDataFiltered =
    breedData.filter((element) => element.PetType === petType.value);

  return breedDataFiltered;
}

export function saveSelectedPetNumber(petQuoteSelected) {
  return { petQuoteSelected, type: SAVE_SELECTED_PET_NUMBER };
}

export function continueWithoutLogin() {
  return { type: CONTINUE_WITHOUT_LOGIN };
}

async function makeRateData({ Data, petQuote, planParams = {} }) {
  const plans = petQuote.Plans.map((plan, index) => ({
    id: plan.Plan,
    index,
    maxAnnual: plan.MaxAnnual,
    name: plan.PlanName,
  }));

  let selectedPlanIndex =
    plans.findIndex((plan) => plan.id === petQuote.Plan);
  let selectedPlanData =
    plans.find((plan) => plan.id === petQuote.Plan);

  if (planParams.plan) {
    const selectedPlanIndexAux = plans
      .findIndex((plan) => plan.name.toUpperCase()
        === planParams.plan.toUpperCase());
    if (selectedPlanIndexAux >= 0) {
      selectedPlanIndex = selectedPlanIndexAux;
      selectedPlanData = plans[selectedPlanIndex];
    }
  }

  let selectedDeductibleId = petQuote.Deductible;
  let selectedDeductibleQty = petQuote.deductibleName;
  if (planParams.deductible) {
    const selectedDeductible = petQuote.Deductibles
      .find((deductible) => deductible.DollarVal
        === parseInt(planParams.deductible, 10));
    if (selectedDeductible) {
      selectedDeductibleId = selectedDeductible.Id;
      selectedDeductibleQty = selectedDeductible.Description;
    }
  }

  let selectedReimbursementId = petQuote.Reimbursement;
  let selectedReimbursementQty = petQuote.ReimbursementName;
  if (planParams.reimbursement) {
    const selectedReimbursement = petQuote.Reimbursements
      .find((reimbursement) => reimbursement.PercentVal
        === (planParams.reimbursement / 100));
    if (selectedReimbursement) {
      selectedReimbursementId = selectedReimbursement.Id;
      selectedReimbursementQty = selectedReimbursement.Description;
    }
  }

  return {
    data: Data,
    deductibleId: selectedDeductibleId,
    deductibleQuantity: selectedDeductibleQty,
    isUpdate: false,
    monthlyPremium: petQuote.monthlyPremium,
    partnerAnnualSaving: petQuote.PartnerAnnualSaving,
    petName: petQuote.petName,
    plans,
    quoteId: petQuote.petQuoteId,
    reimbursementId: selectedReimbursementId,
    reimbursementPercentage: selectedReimbursementQty,
    selectedPlan: selectedPlanData.id,
    selectedPlanAmount: selectedPlanData.maxAnnual,
    selectedPlanIndex,
    selectedPlanPackaged: Data?.groupCodeDscr
      ? CUSTOM_PLAN_ID : petQuote.PrePackagedPlanId,
    type: QUOTING_PLAN_DATA_LOADED,
  };
}

export function saveSubId(subId) {
  return { subId, type: SUB_ID_SAVED };
}

function costcoRateSummary() {
  return async (dispatch, getState) => {
    const url = 'api/Quote/GetSelectedRate';
    let response = null;
    let post = null;
    let payload = null;
    try {
      const {
        customerZipCode,
        data,
        effectiveDateCustom,
        parameters,
        partners,
        plans,
        quoteSelectedValues,
        sessionInformation: { nopCommerceUser, userEmail },
        openQuoteGuid,
      } = getState().quoting;
      const {
        effectiveDate,
        groupCode,
        groupCodeDscr,
        petQuoteResponseList,
      } = data;
      const { costcoMembership, isCostco, partnerId, petPickupDate } = partners;

      post = {
        DiamondClientId: nopCommerceUser.DiamonClientdId,
        EffectiveDate: effectiveDateCustom || effectiveDate,
        eMail: userEmail || parameters.email,
        employerName: '',
        GroupCode: groupCode,
        GroupCodeDscr: groupCodeDscr,
        IsEb: false,
        IsOpenQuote: !!openQuoteGuid,
        nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
        Partner: {
          IsFeeWaived: costcoMembership.isFeeWaived,
          MembershipId: isCostco ? costcoMembership.membershipNumber : '',
          MembershipType: isCostco ? costcoMembership.membershipTypeName : '',
          MembershipTypeDescription: isCostco
            ? costcoMembership.membershipType : '',
          PartnerGuid: partnerId || '',
        },
        PartnerGuid: partnerId || '',
        petQuotes: petQuoteResponseList.map((quote) => {
          const selectedValues = quoteSelectedValues.find(
            (item) => item.quoteId === quote.petQuoteId,
          );
          const selectedPlanByIndex = plans[selectedValues.selectedPlanIndex];

          return {
            deductible: selectedValues.deductibleId,
            Id: quote.petQuoteId,
            Modifiers: quote.modifiers || [],
            PetAge: quote.petAgeName,
            PetBreed: quote.breedName,
            PetBreedId: quote.breedId,
            PetName: quote.petName,
            PetSex: quote.genderName,
            PetType: quote.petType === PetType.Dog.value
              ? PetType.Dog.name : PetType.Cat.name,
            plan: selectedPlanByIndex.id,
            reimbursement: selectedValues.reimbursementId,
            selectedPlanIndex: selectedValues.selectedPlanIndex,
          };
        }),
        PickupDate: petPickupDate,
        QuoteGuid: openQuoteGuid || '',
        quoteId: 1,
        ZipCode: customerZipCode,
      };

      response = await apiCall(url, { post, withTimeout: true });
      payload = await response.json();

      if (payload.IsValid) {
        dispatch({ data: payload.Data, type: RATE_SUMMARY_SUCCESS });
      }
    } catch (exception) {
      dispatch(logError({
        action: 'costcoRateSummary',
        description: exception.toString()
          || 'Error getting selected rate.',
        endpoint: url,
        exception,
        request: post,
        response: payload,
        statusCode: response?.status,
      }));
    }
  };
}

export const fetchTemporaryMessage = (isCostco) => async (dispatch) => {
  const site = isCostco ? 'Costco' : 'D2C';
  const published = true;

  try {
    const url = `api/Contentful/TemporaryMessageQuote/${site}/${published}`;
    const messageResponse = await apiCall(url);
    const messagePayload = await messageResponse.json();

    if (messagePayload.IsValid && messagePayload.Data) {
      dispatch({ payload: messagePayload.Data, type: FETCH_MESSAGE_SUCCESS });
    } else {
      dispatch({ type: FETCH_MESSAGE_NO_DATA });
    }
  } catch (error) {
    dispatch({ payload: error.message, type: FETCH_MESSAGE_NO_DATA });
  }
};

function trackLoadEvent({ Data, quoting }) {
  if (useTrackingEvents) {
    trackingLoadQuote({
      codeMask: quoting.parameters.groupCodeDscr,
      email: quoting.parameters.email,
      guidData: quoting.guidData,
      modifierList: Data.insuranceProduct.InsuranceModifiers,
      petQuoteList: Data.petQuoteResponseList,
      promoCode: Data.groupCode || '',
      quoteSelectedValues: quoting.quoteSelectedValues,
      subId: quoting.subId.Value,
      zipCode: Data.zipCode,
    });
  }
}

function saveQuoteFindModifiers({ modifiersData = [], modifiers = [] }) {
  const modifierSelected = modifiers.filter((item) => item.isSelected !== null);
  const body = {
    ExtraCarePackAdded: null,
    PerIncidentCoPayAdded: false,
    VetFeesAdded: null,
    WellnessPlanType: null,
  };

  if (modifierSelected.length < 1) {
    return body;
  }

  const {
    EXTRA_CARE_PACK_PSM_ID,
    PER_INCIDENT_CO_PAY,
    VETERINARY_EXAM_FEES_PSM_ID,
    WELLNESS_PSM_ID,
  } = PowerUpsRelation;
  const modifierFilter = modifiersData
    .filter((item) => item.InsuranceModifierTypeId === POWERUP_TYPE
      && item.IsActive && item.IsVisible);

  const compare = (id) => (item) => item.IsSelected
    && item.PMSModifierId === id;

  const extraCarePack = modifierFilter.find((item) => item.PMSModifierId
    === EXTRA_CARE_PACK_PSM_ID)?.IsSelected;
  const veterinary = modifierFilter.find((item) => item.PMSModifierId
    === VETERINARY_EXAM_FEES_PSM_ID)?.IsSelected;
  const wellnessPsm = modifierFilter.find((item) => item
    .PMSModifierId === WELLNESS_PSM_ID
    && item.IsSelected !== null) ?? null;
  let wellnessBundle = null;
  const perIncidentCoPay = modifierFilter.find(compare(PER_INCIDENT_CO_PAY));

  if (wellnessPsm) {
    if (wellnessPsm.IsSelected) {
      wellnessBundle = wellnessPsm
        .BundleInsuranceModifiers.find((item) => item.IsSelected);
    } else {
      wellnessBundle = 0;
    }
  }

  return {
    ExtraCarePackAdded: extraCarePack,
    PerIncidentCoPayAdded: !!perIncidentCoPay,
    VetFeesAdded: veterinary,
    WellnessPlanType: wellnessBundle && wellnessBundle.CoverageLimitId,
  };
}

export function saveQuote({
  cloudClientId = 0,
  EffectiveDate,
  email,
  firstName,
  groupCodeDscr,
  lastName,
  origin,
  partnerId,
  petQuoteResponseList,
  pickupDate,
  promoCode,
  quoteId = 0,
  quoteSelectedValues,
  QuoteSubId,
  quoteType = SAVE_QUOTE_TYPE.customer,
  zipCode,
}) {
  return async (dispatch, getState) => {
    const type = useFSCLeads ? `/${quoteType}` : '';
    const url = `api/Quote/SaveQuoteEB${type}`;
    let saveQuoteResponse = null;
    let saveQuotePayload = null;
    let body = null;
    try {
      dispatch({ type: SAVE_QUOTE_ATTEMPT });

      const agentId = getState().quoting.parameters?.agentId || '';

      const petQuotes = petQuoteResponseList
        .map((petResponse) => {
          const petSelectedValue = quoteSelectedValues
            .find((element) => element.quoteId === petResponse.petQuoteId);
          const { isMonthlyPlan, modifiers } = petSelectedValue;
          const annualPremium = petSelectedValue.monthlyAmountPlan * 12;

          return {
            ...saveQuoteFindModifiers({
              modifiers,
              modifiersData: petResponse.InsuranceModifiers,
            }),
            cloudOrderId: petResponse.cloudOrderId,
            Id: petResponse.quoteId || petResponse.petQuoteId,
            PetAge: petResponse.petAgeName,
            PetBreed: petResponse.breedName,
            PetBreedId: petResponse.breedId,
            PetName: petResponse.petName,
            PetSex: petResponse.genderName,
            PetType: getPetNameFromValue(petResponse.petType),
            userSelectedInfoPlan: {
              annualPremium: isMonthlyPlan ? 0 : annualPremium,
              deductibleName: petSelectedValue.deductibleQuantity,
              monthlyPremium: isMonthlyPlan
                ? petSelectedValue.monthlyAmountPlan : 0,
              plan: petSelectedValue.selectedPlan,
              prePackagedPlanId: petSelectedValue.selectedPlanPackaged,
              reimbursementName: petSelectedValue.reimbursementPercentage !== ''
                ? petSelectedValue.reimbursementPercentage
                : petResponse.ReimbursementName,
            },
          };
        });

      body = {
        agentId,
        cloudClientId,
        EffectiveDate,
        eMail: email,
        firstName,
        GroupCode: promoCode,
        GroupCodeDscr: groupCodeDscr,
        IsEB: false, // this always will be false for this project
        lastName,
        origin,
        Partner: {
          PartnerGuid: partnerId,
        },
        petQuotes,
        PickupDate: pickupDate,
        quoteId,
        QuoteSubId,
        ZipCode: zipCode,
      };

      saveQuoteResponse = await apiCall(
        url,
        {
          post: body,
          withTimeout: true,
        },
      );
      saveQuotePayload = await saveQuoteResponse.json();

      if (saveQuotePayload.IsValid) {
        dispatch({
          isAuto: quoteType === SAVE_QUOTE_TYPE.auto,
          quoteId: saveQuotePayload?.Data?.quoteId,
          type: SAVED_QUOTE,
        });
      } else {
        dispatch({
          message: saveQuotePayload.Message
            || 'Error saving quote.',
          type: SAVE_QUOTE_ERROR,
        });
        dispatch(logError({
          action: 'saveQuote',
          description: saveQuotePayload.Message
            || 'Error saving quote.',
          endpoint: url,
          request: body,
          response: saveQuotePayload,
          statusCode: saveQuoteResponse.status,
        }));
      }
    } catch (exception) {
      dispatch({
        message: 'Unexpected error saving quote.',
        type: SAVE_QUOTE_ERROR,
      });
      dispatch(logError({
        action: 'saveQuote',
        description: exception.toString(),
        endpoint: url,
        exception,
        request: body,
        response: saveQuotePayload,
        statusCode: saveQuoteResponse?.status,
      }));
    }
  };
}

export function rateQuote({
  body,
  firstLoad = false,
  isRemovePet = false,
  parameters = null,
  planParams = {},
  showLoading = false,
  stayLoadingAtFinish = false,
  tag = '',
  update = false,
}) {
  return async (dispatch, getState) => {
    if (!update || showLoading) {
      dispatch({ type: LOADING_QUOTE_DATA });
    }

    const url = 'api/Quote/Rate/';
    let statusToLog = '';
    let post = null;
    let payloadRateQuote = null;
    try {
      const subIdStored = getState().quoting.subId.Name;
      if (!subIdStored) {
        const params = parameters || getState().quoting.parameters;

        const quoteSubId =
          validatePartnerIds({ parameters: params, synonyms: subIdSynonyms });

        dispatch(saveSubId(quoteSubId));
      }

      const state = getState();

      const QuoteSubId = state.quoting.subId;

      const {
        isGoodDog,
        partnerId: PartnerGuid,
        petPickupDate,
      } = state.quoting.partners;

      const Partner = PartnerGuid ? { PartnerGuid } : {};

      let PickupDate = isGoodDog ? petPickupDate : null;
      const now = new Date().toISOString().split('T')[0];

      if (PickupDate === now) {
        // Today is valid date for user, but not for rate
        PickupDate = null;
      }

      post = {
        ...body,
        IsOpenQuote: !!state.quoting.openQuoteGuid,
        marketingOptIn: toBoolean(state.quoting.parameters.marketingOptIn),
        Partner,
        PickupDate,
        QuoteGuid: state.quoting.openQuoteGuid || '',
        QuoteSubId,
      };
      const rateQuoteResponse = await apiCall(url, {
        post,
        withTimeout: true,
      });
      statusToLog = rateQuoteResponse.status;

      payloadRateQuote = await rateQuoteResponse.json();

      if (payloadRateQuote.IsValid) {
        const { quoteId } = body;
        const { Data } = payloadRateQuote;
        const { petQuoteResponseList } = Data;
        const petQuote = petQuoteResponseList
          .find((item) => item.petQuoteId === quoteId);

        const rateData = await makeRateData({ Data, petQuote, planParams });

        await dispatch({
          ...rateData,
          isUpdate: update,
          quoteId,
          stayLoadingAtFinish,
        });
        if (!isRemovePet) {
          await dispatch({ type: NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL });
        }
        await dispatch({ type: RECALCULATE_LOCAL_TOTAL });
        await sleep(300);

        const { quoting } = getState();
        trackLoadEvent({ Data, quoting });

        if (useFSCLeads) {
          const {
            groupCode,
            groupCodeDscr,
            zipCode,
          } = Data;
          const {
            customerValidationResponse,
            quoteSelectedValues,
            saveQuote: {
              quoteId: quoteGuid,
            },
            partners: { partnerId },
          } = quoting;
          const cloudClientId = customerValidationResponse?.id || 0;
          await dispatch(saveQuote({
            cloudClientId,
            EffectiveDate: createEffectiveDate(),
            email: quoting.newCustomer.email
              || quoting.parameters.email,
            firstName: quoting.newCustomer.name || '',
            groupCode,
            groupCodeDscr,
            lastName: quoting.newCustomer.lastName || '',
            origin: window.location.origin,
            partnerId,
            petQuoteResponseList,
            pickupDate: petPickupDate,
            promoCode: quoting.newPet.promoCode,
            quoteId: quoteGuid,
            quoteSelectedValues,
            QuoteSubId: quoting.subId,
            quoteType: SAVE_QUOTE_TYPE.auto,
            zipCode,
          }));
        }

        dispatch({ tag, type: LOADING_QUOTE_DATA_FINISH });
      } else {
        dispatch(logError({
          action: 'rateQuote',
          description: payloadRateQuote.Message
            || 'Error saving quote.',
          endpoint: url,
          request: post,
          response: payloadRateQuote,
          statusCode: statusToLog,
        }));

        if (firstLoad) {
          const query = new URLSearchParams(parameters);
          if (query.get('partnerGuid')) {
            query.set('refer', query.get('partnerGuid'));
            query.delete('partnerGuid');
          }
          const redirectUrl = `${LANDING_URL}?${query.toString()}`;

          window.location.replace(redirectUrl);
          return;
        }

        dispatch({
          message: payloadRateQuote.Message
            || 'Error rating quote.',
          redirectToLanding: !update,
          type: RATE_QUOTE_ERROR,
        });
      }
    } catch (exception) {
      dispatch(logError({
        action: 'rateQuote',
        description: exception.toString()
          || 'Error rating quote.',
        endpoint: url,
        exception,
        request: post,
        response: payloadRateQuote,
        statusCode: statusToLog,
      }));
      dispatch({ message: exception, type: RATE_QUOTE_ERROR });
    }
  };
}

export function getTomorrowEffectiveDate() {
  const today = new Date();
  const tomorrow = moment(today).add(1, 'days')
    .format('YYYY-MM-DD-Thh:mm:ss');
  return tomorrow;
}

export function loadAddressByZipCode(zipCode) {
  return async (dispatch) => {
    try {
      dispatch({ type: NEW_CUSTOMER_ADDRESS_LOAD_ATTEMPTED });
      const legacyAPI = `api/State/GetStateByZipCode/${zipCode}`;
      const purchaseAPI = `purchase/State/GetStateByZipCode/${zipCode}`;
      const { QuoteSiteD2CPurchaseV1T159710 } = getFeatureFlagFromStorage();
      const url = QuoteSiteD2CPurchaseV1T159710 ? purchaseAPI : legacyAPI;
      const response = await apiCall(url);
      const payload = await response.json();

      if (payload.IsValid) {
        dispatch({ address: payload.Data, type: ADDRESS_BY_ZIP_CODE_LOADED });
      } else {
        dispatch({ type: FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE });
      }
    } catch (_) {
      dispatch({ type: FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE });
    }
  };
}

export function validateZipCodeComingSoon({
  email,
  zipcode,
  loadAddress = true,
}) {
  function validateZipCodeTennessee(zipCode) {
    const zipCodePattern = /^\d{5}$/;
    const tennesseeZipCodePattern =
      /^(370|371|372|373|374|375|376|377|378|379|380|381|382|383|384|385)/;
    if (!zipCodePattern.test(zipCode)) {
      return false;
    }
    if (tennesseeZipCodePattern.test(zipCode)) {
      return false;
    }
    return true;
  }

  return async (dispatch) => {
    try {
      dispatch({ type: ZIP_CODE_VALIDATION_ATTEMPT });

      const validState = validateZipCodeTennessee(zipcode);

      const type = validState
        ? ZIP_CODE_VALIDATION_SUCCESS : ZIP_CODE_VALIDATION_FAILED;

      dispatch({ type });
      dispatch({ email, type: ZIP_CODE_CUSTOMER_UPDATED, zipcode });

      if (loadAddress) {
        dispatch(loadAddressByZipCode(zipcode));
      }

      return validState;
    } catch (exception) {
      dispatch({ type: ZIP_CODE_VALIDATION_CRASHED });
      dispatch(logError({
        action: 'validateZipCodeComingSoon',
        description: exception.toString(),
        endpoint: '',
        exception,
        statusCode: '',
      }));
      return false;
    }
  };
}

async function partnerIdLoadedRequest(partnerId) {
  const url = `api/Partner/GetPartnerAppearanceByPartnerGuid/${partnerId}`;
  const response = await apiCall(url);

  if (response.status !== 200) {
    // if partnerId not found get status === 204
    return { IsValid: false };
  }

  const payload = await response.json();

  return payload;
}

async function validateCostcoRate({ dispatch, parameters }) {
  const {
    email,
    partnerGuid,
    petAge,
    petBreed,
    petBreedId,
    petName,
    petSex,
    petZipCode,
  } = parameters;

  if (!partnerGuid) {
    return true;
  }

  const partnerResponse = await partnerIdLoadedRequest(partnerGuid);

  if (partnerResponse.IsValid) {
    dispatch({
      data: partnerResponse.Data,
      partnerId: partnerGuid,
      type: PARTNER_ID_INFORMATION_LOADED,
    });

    const validZipCodeComingSoon =
      validateZipCodeComingSoon({ zipcode: petZipCode });

    if (!validZipCodeComingSoon) {
      dispatch({
        type: NOTIFY_ME_EMAIL_VALUES_SAVED,
        valuesEmail: {
          email,
          invalidQuote: true,
          petAge,
          petBreed,
          petBreedId,
          petName,
          petSex,
          zipcode: petZipCode,
        },
      });

      return false;
    }

    dispatch(loadAddressByZipCode(petZipCode));
  }

  return true;
}

export function loadQuoteData({
  firstLoad = false,
  parameters,
  update = false,
}) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_QUOTE_DATA });

    const planParams = {
      deductible: parameters.deductible,
      plan: parameters.plan,
      reimbursement: parameters.reimbursement,
    };

    try {
      const body = {
        DiamondClientId: 0,
        EffectiveDate: null,
        eMail: parameters.email,
        employerName: null,
        GroupCode: parameters.groupCode,
        GroupCodeDscr: parameters.groupCodeDscr,
        nopCommerceClientId: 0,
        petQuotes: [{
          Id: '1',
          PetAge: parameters.petAge,
          petBreed: parameters.petBreed,
          PetBreedId: parameters.petBreedId,
          petName: parameters.petName,
          petSex: parameters.petSex,
          petType: parameters.petType,
        }],
        PickupDate: parameters.petPickupDate,
        quoteId: 1,
        TestEffectiveDate: parameters.testEffectiveDate,
        ZipCode: parameters.petZipCode,
      };

      const rateValid = await validateCostcoRate({ dispatch, parameters });

      if (!rateValid) {
        dispatch({ type: LOADING_QUOTE_DATA_FINISH });

        return;
      }

      await dispatch(rateQuote({
        body,
        firstLoad,
        parameters,
        planParams,
        tag: AFTER_RATE_TAG.createRate,
        update,
      }));

      if (useFSCLeads) {
        const { quoting: { data } } = getState();
        dispatch(sendFSCLead({
          age: Number(getAgeInYears(parameters.petAge)),
          breed: parameters.petBreed,
          breedId: Number(parameters.petBreedId),
          email: parameters.email,
          gender: parameters.petSex,
          marketingOpt: toBoolean(parameters.marketingOptIn),
          petName: parameters.petName,
          petType: parameters.petType,
          promoCode: data?.groupCode || parameters.groupCode,
          zipCode: parameters.petZipCode,
        }));
      }
    } catch (exception) {
      dispatch(logError({
        action: 'loadQuoteData',
        description: exception.toString(),
        endpoint: '',
        exception,
        statusCode: '',
      }));
      dispatch({
        message: 'Unexpected error rating quote.',
        type: RATE_QUOTE_ERROR,
      });
    }
  };
}

export function saveURLParameters({ parameters, search }) {
  return async (dispatch) => {
    dispatch({ parameters, search, type: SAVE_URL_PARAMETERS });
  };
}

export function saveMonthlyPlanSelected({
  annualAmount,
  monthlyAmount,
  partnerAnnualSaving,
}) {
  return async (dispatch) => {
    dispatch({
      annualAmount,
      monthlyAmount,
      partnerAnnualSaving,
      type: SAVE_MONTHLY_PLAN_SELECTED,
    });
    dispatch({ type: RECALCULATE_LOCAL_TOTAL });
  };
}

export function recalculateTotalPrices() {
  return { type: NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL };
}

export function saveSelectedReimbursement({ description, id }) {
  return async (dispatch) => {
    dispatch({ description, id, type: SAVE_REIMBURSEMENT_SELECTED });
  };
}

export function saveDeductibleSelected({ description, id }) {
  return async (dispatch) => {
    dispatch({ description, id, type: SAVE_DEDUCTIBLE_SELECTED });
  };
}

// TODO - delete me when wellness modifier be ready
export function wellnessSelected(quantity) {
  return async (dispatch) => {
    dispatch({ type: QUOTE_POWER_UP_CHANGED });
    dispatch({
      id: -1,
      isSelected: !!quantity,
      type: RATE_QUOTE_UPDATE_ATTEMPT,
    });
    await sleep(2000);
    dispatch({ quantity, type: QUOTE_WELLNESS_SELECTED });
    dispatch({ type: RECALCULATE_LOCAL_TOTAL });
    dispatch({ id: -1, type: RATE_QUOTE_UPDATE_LOADED });
  };
}

export function loadQuoteDataByStorage(data) {
  return async (dispatch) => {
    dispatch({ data, type: QUOTE_DATA_LOADED_BY_STORAGE });
  };
}

export function selectedPlan({ planId, planAmount, planPackagedId }) {
  return async (dispatch) => {
    dispatch({ planAmount, planId, planPackagedId, type: QUOTE_PLAN_SELECTED });
  };
}

export function planChangedByQuoteId({
  planId,
  planAmount,
  planPackagedId,
  quoteId,
}) {
  return {
    planAmount,
    planId,
    planPackagedId,
    quoteId,
    type: QUOTE_PLAN_SELECTED_BY_QUOTEID,
  };
}

export function loadBreedData() {
  return async (dispatch) => {
    const legacyAPI = 'api/Quote/Breeds';
    const purchaseAPI = 'purchase/quote/breeds';
    const { QuoteSiteD2CPurchaseV1T159710 } = getFeatureFlagFromStorage();
    const url = QuoteSiteD2CPurchaseV1T159710 ? purchaseAPI : legacyAPI;

    let breedPayload = null;
    try {
      const breedResponse = await apiCall(url, { withTimeout: true });
      breedPayload = await breedResponse.json();

      if (breedPayload.IsValid) {
        const breedDog =
          getBreedDataFilteredByType(breedPayload.Data, PetType.Dog);
        const breedCat =
          getBreedDataFilteredByType(breedPayload.Data, PetType.Cat);

        dispatch({
          breedCat,
          breedDog,
          data: breedPayload.Data,
          type: BREED_DATA_LOADED,
        });
      } else {
        dispatch({
          message: breedPayload.Message
            || 'Error retrieving breed data.',
          type: BREED_DATA_ERROR,
        });
        dispatch(logError({
          action: 'loadBreedData',
          description: breedPayload.Message
            || 'Error retrieving breed data.',
          endpoint: url,
          response: breedPayload,
          statusCode: breedResponse.status,
        }));
      }
    } catch (exception) {
      dispatch({ message: exception, type: BREED_DATA_ERROR });
      dispatch(logError({
        action: 'loadBreedData',
        description: exception.toString(),
        endpoint: url,
        exception,
        response: breedPayload,
        statusCode: '',
      }));
    }
  };
}

export function saveNewPetAge(petAge) {
  return { petAge, type: NEW_PET_AGE_SAVED };
}

export function saveNewPetBreed({ petBreed, petBreedId }) {
  return { petBreed, petBreedId, type: NEW_PET_BREED_SAVED };
}

export function saveNewPetName(petName) {
  return { petName, type: NEW_PET_NAME_SAVED };
}

export function saveNewPetPromoCode(promoCode) {
  return { promoCode, type: NEW_PET_PROMO_CODE_SAVED };
}

export function saveNewPetGroupCodeDscr(groupCodeDscr) {
  return { groupCodeDscr, type: NEW_PET_GROUP_DSCR_CODE_SAVED };
}

export function partnerIdLoaded(partnerId) {
  return async (dispatch) => {
    dispatch({ type: PARTNER_ID_LOAD_INFORMATION_ATTEMPT });

    try {
      const url = `api/Partner/GetPartnerAppearanceByPartnerGuid/${partnerId}`;
      const response = await apiCall(url);
      const payload = await response.json();

      if (payload.IsValid && payload.Data.PartnerGuid) {
        const { PartnerName } = payload.Data;

        dispatch({
          data: payload.Data,
          partnerId,
          partnerName: PartnerName,
          type: PARTNER_ID_INFORMATION_LOADED,
        });
      } else {
        dispatch({ type: PARTNER_ID_INFORMATION_FAILED });
      }
    } catch (_) {
      dispatch({ type: PARTNER_ID_INFORMATION_FAILED });
    }
  };
}

export function saveNewPetZipCode(zipcode) {
  return { type: NEW_PET_ZIPCODE_SAVED, zipcode };
}

export function saveNewPetEmail(email) {
  return { email, type: NEW_PET_EMAIL_SAVED };
}

export function saveNewPetType(petType) {
  return { petType, type: NEW_PET_TYPE_SAVED };
}

export function saveNewPetSex(petSex) {
  return { petSex, type: NEW_PET_SEX_SAVED };
}

export function saveNewPetPickupDate(pickupDate) {
  return { pickupDate, type: NEW_PET_PICKUP_DATE_SAVED };
}

export function loadAgeData() {
  return async (dispatch) => {
    let agePayload = null;
    let url = '';

    try {
      const legacyAPI = 'api/Quote/Ages';
      const purchaseAPI = 'purchase/quote/ages';
      const { QuoteSiteD2CPurchaseV1T159710 } = getFeatureFlagFromStorage();
      url = QuoteSiteD2CPurchaseV1T159710 ? purchaseAPI : legacyAPI;

      const ageResponse = await apiCall(url, { withTimeout: true });
      agePayload = await ageResponse.json();

      if (agePayload.IsValid) {
        dispatch({ data: agePayload.Data, type: AGE_DATA_LOADED });
      } else {
        dispatch({
          message: agePayload.Message
            || 'Error retrieving age data.',
          type: AGE_DATA_ERROR,
        });
        dispatch(logError({
          action: 'loadAgeData',
          description: agePayload.Message
            || 'Error retrieving age data.',
          endpoint: url,
          response: agePayload,
          statusCode: ageResponse.status,
        }));
      }
    } catch (exception) {
      dispatch({ message: exception, type: AGE_DATA_ERROR });
      dispatch(logError({
        action: 'loadAgeData',
        description: exception.toString(),
        endpoint: url,
        exception,
        response: agePayload,
        statusCode: '',
      }));
    }
  };
}

export function saveSelectedEditPetModal(editPetModalVisible) {
  return { editPetModalVisible, type: SAVE_SELECTED_EDIT_PET_MODAL };
}

export function saveSelectedAddPetModal(addPetModalVisible) {
  return { addPetModalVisible, type: SAVE_SELECTED_ADD_PET_MODAL };
}

export function saveSelectedRemovePetModal(removePetModalVisible) {
  return { removePetModalVisible, type: REMOVE_PET_MODAL_VISIBLE };
}

export function saveUpdatedPet() {
  return { type: SAVE_UPDATED_PET };
}

export function changeDeductibleLegend(deductibleLegend) {
  return { deductibleLegend, type: DEDUCTIBLE_LEGEND_CHANGED };
}

export function resetPowerUpAriaLabel() {
  return { type: RESET_POWER_UP_ARIA_LABEL };
}

function handlePowerUpChanged({ id, isSelected }) {
  return async (dispatch) => {
    dispatch({ id, isSelected, type: QUOTE_POWER_UP_CHANGED });
    dispatch({ id, isSelected, type: RATE_QUOTE_UPDATE_ATTEMPT });
  };
}

export function updateRateQuote({
  diamondClientId,
  ebGuID,
  effectiveDateCustom,
  eMail,
  employerName,
  isRemovePet = false,
  modifiers,
  nopCommerceClientId,
  parameters,
  petPickupDate,
  petQuoteList,
  promoCode,
  quoteId,
  showLoading,
  tag = 'updateRateQuote',
}) {
  return async (dispatch) => {
    const body = {
      DiamondClientId: diamondClientId,
      ebGuID,
      EffectiveDate: null,
      eMail,
      employerName,
      GroupCode: promoCode,
      GroupCodeDscr: parameters.groupCodeDscr,
      nopCommerceClientId,
      petQuotes: [
        ...petQuoteList.map((quote) => ({
          cloudOrderId: quote.cloudOrderId || 0,
          Id: quote.petQuoteId,
          Modifiers: quote.petQuoteId === quoteId
            ? modifiers : quote.modifiers,
          PetAge: quote.petAgeName,
          PetBreed: quote.breedName,
          PetBreedId: quote.breedId,
          PetName: quote.petName,
          PetSex: quote.genderName,
          PetType: quote.petType,
        })),
      ],
      PickupDate: petPickupDate || parameters.petPickupDate,
      quoteId,
      TestEffectiveDate: effectiveDateCustom,
      ZipCode: parameters.petZipCode,
    };

    await dispatch(rateQuote({
      body,
      isRemovePet,
      showLoading,
      tag,
      update: true,
    }));

    if (!showLoading) {
      dispatch({ tag, type: LOADING_QUOTE_DATA_FINISH });
    }
  };
}

/**
  * Recalculate Reimbursement/Deductible values from PrePackagedPlans and
  * total on quoteSelectedValues (values can change from some age/breed)
  */
export function recalculatePackagedValuesSelected() {
  return async (dispatch) => {
    dispatch({ type: RECALCULATE_PACKAGED_VALUES_SELECTED });
    dispatch({ type: NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL });
  };
}

export function updatePet({ body, update }) {
  return async (dispatch) => {
    const tag = AFTER_RATE_TAG.updatePet;

    await dispatch(rateQuote({ body, showLoading: true, tag, update }));

    const { quoteId, petQuotes } = body;

    const petUpdated =
      petQuotes.find((quote) => quote.Id === quoteId) || {};

    const { Cat, Dog } = PetType;

    await recalculatePackagedValuesSelected()(dispatch);
    dispatch({
      data: {
        petName: petUpdated.PetName,
        petQuoteId: quoteId,
        petType: petUpdated.PetType === Dog.name ? Dog.value : Cat.value,
      },
      type: PET_UPDATED,
    });
  };
}

export function validateAllPetsPowerups() {
  return (dispatch, getState) => {
    const { quoting } = getState();
    const petsWithQuoteError = [];

    if (!quoting.data) {
      dispatch({ petsWithQuoteError, type: ALL_PETS_POWERUPS_VALIDATED });
      return false;
    }
    const { data } = quoting;
    data.petQuoteResponseList.forEach((petQuote) => {
      const powerUpValidation = validatePowerupsSelected(petQuote);
      if (!powerUpValidation.isValid) {
        petsWithQuoteError.push(petQuote.petQuoteId);
      }
    });

    dispatch({ petsWithQuoteError, type: ALL_PETS_POWERUPS_VALIDATED });
    return !petsWithQuoteError.length;
  };
}

export function updatePowerUp({
  diamondClientId,
  ebGuID,
  effectiveDateCustom,
  eMail,
  employerName,
  guidPayload,
  id,
  isSelected,
  modifiers,
  nopCommerceClientId,
  parameters,
  petQuoteList,
  promoCode,
  quoteId,
  showLoading,
}) {
  return async (dispatch) => {
    try {
      dispatch(handlePowerUpChanged({ id, isSelected }));
      await dispatch(updateRateQuote({
        diamondClientId,
        ebGuID,
        effectiveDateCustom,
        eMail,
        employerName,
        guidPayload,
        modifiers,
        nopCommerceClientId,
        parameters,
        petQuoteList,
        promoCode,
        quoteId,
        showLoading,
        tag: AFTER_RATE_TAG.updatePowerUp,
      }));

      dispatch(validateAllPetsPowerups());
      await sleep(800);
      dispatch({ id, isSelected, type: RATE_QUOTE_UPDATE_LOADED });
    } catch (_) {
      dispatch({ id, isSelected: !isSelected, type: RATE_QUOTE_UPDATE_LOADED });
    }
  };
}

export function removePet({
  diamondClientId,
  ebGuID,
  effectiveDateCustom,
  eMail,
  employerName,
  guidPayload,
  modifiers,
  nopCommerceClientId,
  parameters,
  petQuoteList,
  promoCode,
  quoteId,
  quoteSelectedValues = [],
  removePetIds = [],
  toggleOpen = () => { },
  showLoading = false,
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: PET_QUOTE_REMOVAL_ATTEMPT });
      await dispatch(updateRateQuote({
        diamondClientId,
        ebGuID,
        effectiveDateCustom,
        eMail,
        employerName,
        guidPayload,
        isRemovePet: true,
        modifiers,
        nopCommerceClientId,
        parameters,
        petQuoteList,
        promoCode,
        quoteId,
        showLoading,
        tag: AFTER_RATE_TAG.removePet,
      }));

      dispatch({
        petQuoteSelected: quoteSelectedValues
          .find((petQuote) => petQuote.quoteId === quoteId).quoteId,
        quoteSelectedValues,
        type: PET_QUOTE_REMOVAL_SUCCEED,
      });
      await sleep(1000);
      dispatch({ ids: removePetIds, type: PET_REMOVED });
      dispatch(saveSelectedRemovePetModal(false));
      await sleep(1000);
      dispatch({ type: NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL });
      toggleOpen();
    } catch (_) {
      dispatch({ type: PET_QUOTE_REMOVAL_FAILED });
      toggleOpen();
    }
  };
}

export function resetState() {
  return { type: RESET_QUOTES_VALUES_SELECTED };
}

export function addPet(body) {
  return async (dispatch, getState) => {
    const petQuote = body.petQuotes
      .find((quote) => body.quoteId === quote.Id);
    const tag = AFTER_RATE_TAG.addPet;

    await dispatch(rateQuote({ body, tag }));

    const { Cat, Dog } = PetType;

    dispatch({
      data: {
        petDateOfBirth: '',
        petName: petQuote.PetName,
        petQuoteId: body.quoteId,
        petTagColor: 1,
        petType: petQuote.PetType === Dog.name ? Dog.value : Cat.value,
      },
      type: PET_ADDED,
    });
    dispatch(saveSelectedPetNumber(body.quoteId));

    if (useFSCLeads) {
      const { quoting: { parameters, newCustomer, data } } = getState();
      dispatch(sendFSCLead({
        age: Number(getAgeInYears(petQuote.PetAge)),
        breed: petQuote.PetBreed,
        breedId: petQuote.PetBreedId,
        email: newCustomer.email || parameters.email,
        gender: petQuote.PetSex,
        marketingOpt: toBoolean(parameters.marketingOptIn),
        petName: petQuote.PetName,
        petType: petQuote.PetType,
        promoCode: data?.groupCode || parameters.groupCode,
        state: newCustomer.address.state || '',
        zipCode: newCustomer.address.zipCode || parameters.petZipCode,
      }));
    }

    // GTM
    dataLayerViewItem({ petIndex: body.quoteId });
  };
}

export function validZipCode({ showLoading, zipcode }) {
  return async (dispatch) => {
    try {
      if (showLoading) {
        dispatch({ type: ZIP_CODE_VALIDATION_ATTEMPT });
      }

      const legacyAPI = `api/State/ValidateZipcode/${zipcode}`;
      const purchaseAPI = `purchase/State/ValidateZipcode/${zipcode}`;
      const { QuoteSiteD2CPurchaseV1T159710 } = getFeatureFlagFromStorage();
      const url = QuoteSiteD2CPurchaseV1T159710 ? purchaseAPI : legacyAPI;

      const request = await apiCall(url);
      const response = await request.json();

      if (showLoading) {
        dispatch({ type: ZIP_CODE_VALIDATION_SUCCESS });
      }

      return response.Data;
    } catch (_) {
      return false;
    }
  };
}

export function notifyMeEmailQuote({
  age,
  breed,
  breedId,
  email,
  gender,
  partnerId,
  petName,
  state,
  zipCode,
}) {
  return async (dispatch) => {
    try {
      dispatch({ type: NOTIFY_ME_BUTTON_CLICKED });

      const body = {
        age,
        breed,
        breedId,
        email,
        gender,
        partnerId,
        petName,
        state,
        zipCode,
      };

      const emailQuoteResponse = await apiCallCostco('api/costco/EmailQuote', {
        post: body,
      });
      const emailQuotePayload = await emailQuoteResponse.json();

      const type = emailQuotePayload ? NOTIFY_ME_SUCCESS : NOTIFY_ME_FAILED;

      dispatch({ type });
    } catch (_) {
      dispatch({ type: NOTIFY_ME_FAILED });
    }
  };
}

export function closeSaveQuoteModalConfirmation() {
  return { type: SAVED_QUOTE_MODAL_VISIBLE };
}

export function saveQuoteMobileVisibleChanged(visible) {
  return {
    type: SAVE_QUOTE_MOBILE_VISIBLE_CHANGED,
    visible,
  };
}

export function newCustomerChanged({ type, value }) {
  return { type, value };
}

/**
 * Open an Quote from different ways; like save quote, marketing link
 * Decode short link to link with all query parameters
 *
 * examples values
 *  customerId = 6a061059-7ede-46f5-9241-ed139999bc82
 *  @link (./partners/partners.constants.js)
 *  partnerId = 21229794-9CAA-4930-ABA6-B543A2C0CCDB
 *  quoteId = f8a464cf-8683-46ac-9bc7-987ad6eff1ce
 *
 * @param {Object} params
 * @param {string} params.customerId - From save quote GUID
 * http://localhost:3000/openQuote?CustomerId=customerId
 * @param {string} params.partnerId - Extra param to third party (like costco)
 * http://localhost:3000/openQuote?quoteid=quoteId&partnerId=
 * @param {string} params.quoteId - From auto save quote
 * localhost:3000/openQuote?QuoteId=quoteId
 * @param {string} params.utmRest - Extra params from marketing tracking
 * like utm_source=MarketingCloud
 * /openQuote?CustomerId=customerId&utm_source=MarketingCloud&utm_some=
 */
export function openQuote({ customerId, partnerId, quoteId, utmRest }) {
  return async (dispatch, getState) => {
    dispatch({ type: OPEN_SAVED_QUOTE_ATTEMPT });
    const isEB = false;
    let url = `api/Quote/OpenQuoteEB/${customerId}/${isEB}`;
    const partnerParameter = partnerId ? `/${partnerId}` : '';
    url = `${url}${partnerParameter}`;
    let openQuoteResponse = null;
    let openQuotePayload = null;

    if (quoteId) {
      url = `api/Quote/OpenAutoSaveQuote?quoteid=${quoteId}`;
    }
    try {
      openQuoteResponse = await apiCall(url, { withTimeout: true });
      openQuotePayload = await openQuoteResponse.json();

      if (!openQuotePayload.IsValid
        && openQuotePayload.Message.toLowerCase().includes('expired')) {
        dispatch({ type: OPEN_SAVED_QUOTE_EXPIRED });
        return;
      }

      if (openQuotePayload.IsValid) {
        const { Data } = openQuotePayload;
        const {
          AgentId,
          Email,
          groupCode,
          groupCodeDscr,
          PartnerGuid,
          petQuoteResponseList,
          PickupDate,
          quoteSubId,
          zipCode,
        } = Data;

        const { petQuoteId, petName, genderName, petAgeName,
          breedId, breedName, petType } = petQuoteResponseList[0];

        const petQuote = petQuoteResponseList
          .find((item) => item.petQuoteId === petQuoteId);
        const plans = petQuote.Plans.map((plan, index) => ({
          id: plan.Plan,
          index,
          maxAnnual: plan.MaxAnnual,
          name: plan.PlanName,
        }));

        const selectedPlanData =
          plans.find((plan) => plan.id === petQuote.Plan);

        dispatch({
          annualAmountPlan: petQuote.annualPremium,
          data: Data,
          deductibleId: petQuote.Deductible,
          deductibleQuantity: petQuote.deductibleName,
          isUpdate: false,
          monthlyPremium: petQuote.monthlyPremium,
          openQuoteGuid: customerId || quoteId,
          partnerAnnualSaving: petQuote.PartnerAnnualSaving,
          petName: petQuote.petName,
          plans,
          quoteId: petQuoteId,
          reimbursementId: petQuote.Reimbursement,
          reimbursementPercentage: petQuote.ReimbursementName,
          selectedPlan: petQuote.Plan,
          selectedPlanAmount: selectedPlanData.maxAnnual,
          selectedPlanIndex: selectedPlanData,
          selectedPlanPackaged: petQuote.PrePackagedPlanId || CUSTOM_PLAN_ID,
          type: QUOTING_PLAN_DATA_LOADED,
        });

        dispatch(saveSelectedPetNumber(petQuoteId));

        const pendingPets =
          petQuoteResponseList.map((pendingPet) => {
            const { Plans, Plan, Deductible, Reimbursement } = pendingPet;

            const planSelected =
              Plans.find((plan) => plan.Plan === Plan);
            const ratingOption = planSelected.RatingOptions
              .find((rating) => rating.DeductibleId === Deductible
                && rating.ReimbursementId === Reimbursement);

            return {
              annualAmountPlan: ratingOption.AnnualPremium,
              deductibleId: Deductible,
              deductibleQuantity: pendingPet.deductibleName,
              partnerAnnualSaving: ratingOption?.PartnerAnnualSaving,
              petName: pendingPet.petName,
              quoteId: pendingPet.petQuoteId,
              reimbursementId: Reimbursement,
              reimbursementPercentage: pendingPet.ReimbursementName,
              selectedPlan: Plan,
              selectedPlanAmount: planSelected.MaxAnnual,
              selectedPlanPackage: pendingPet.PrePackagedPlanId
                || CUSTOM_PLAN_ID,
              total: pendingPet.monthlyPremium,
            };
          });

        await dispatch({ pendingPets, type: OPEN_QUOTE_PENDING_PETS_LOADED });

        let parameters = {
          email: Email,
          id: petQuoteId,
          petAge: petAgeName,
          petBreed: breedName,
          petBreedId: breedId,
          petName,
          petSex: genderName,
          petType: getPetNameFromValue(petType),
          petZipCode: zipCode,
        };

        if (AgentId) {
          parameters.agentId = AgentId;
        }

        if (!partnerId) {
          parameters = {
            ...parameters,
            groupCode,
            groupCodeDscr,
          };
        }

        if (partnerId || PartnerGuid) {
          parameters.partnerGuid = partnerId || PartnerGuid;
          await dispatch(partnerIdLoaded(partnerId || PartnerGuid));
        }

        if (PickupDate) {
          dispatch(saveNewPetPickupDate(PickupDate.split('T')[0]));
        }

        if (quoteSubId && quoteSubId.Name && quoteSubId.Value) {
          parameters[quoteSubId.Name] = quoteSubId.Value;
          dispatch({ subId: quoteSubId, type: SUB_ID_SAVED });
        }

        // utm parameters extra added from marketing in short link
        const searchObject = { ...parameters, ...utmRest };
        const search = `?${serializeForUri(searchObject)}`;

        await dispatch(saveURLParameters({
          parameters: searchObject,
          search,
        }));

        await dispatch(costcoRateSummary());

        const { quoting } = getState();
        trackLoadEvent({ Data, quoting });

        const savedQuoteId = Data.QuotationId;
        await dispatch({ quoteId, savedQuoteId, type: OPEN_QUOTE_LOADED });
      } else {
        dispatch({
          message: openQuotePayload.Message
            || 'Error opening quote.',
          redirectLink: openQuotePayload?.Data?.RedirectLink || '',
          type: OPEN_SAVED_QUOTE_ERROR,
        });
        dispatch(logError({
          action: 'openQuote',
          description: openQuotePayload.Message
            || 'Error opening quote.',
          endpoint: url,
          response: openQuotePayload,
          statusCode: openQuoteResponse.status,
        }));
      }
    } catch (exception) {
      dispatch({
        message: 'Unexpected error opening quote.',
        type: OPEN_SAVED_QUOTE_ERROR,
      });
      dispatch(logError({
        action: 'openQuote',
        description: exception.toString(),
        endpoint: url,
        exception,
        response: openQuotePayload,
        statusCode: openQuoteResponse?.status,
      }));
    }
  };
}

export function petTagColorChanged({ petQuoteId, petTagColor }) {
  return {
    data: { petQuoteId, petTagColor },
    type: PET_TAG_COLOR_CHANGED,
  };
}

export function petTagDateOfBirthChanged({ petQuoteId, petDateOfBirth }) {
  return {
    data: { petDateOfBirth, petQuoteId },
    type: PET_TAG_DATE_OF_BIRTH_CHANGED,
  };
}

export function petTagErrorsChanged(dateErrors) {
  return {
    data: dateErrors,
    type: PET_TAG_DATE_ERRORS_CHANGED,
  };
}

export function clearSecondAddress() {
  return { type: SECOND_ADDRESS_CLEARED };
}

export function toggleYourInfoScrollToTop(toggledState) {
  return { toggledState, type: YOUR_INFO_SCROLL_TO_TOP_TOGGLED };
}

export function toggleSecondPetParentForm(toggledState) {
  return { toggledState, type: SECOND_PARENT_FORM_TOGGLED };
}

export function togglePaymentPlanFrequency({ isMonthly }) {
  return async (dispatch) => {
    dispatch({ isMonthly, type: PAYMENT_PLAN_FREQUENCY_CHANGED });
    dispatch({ type: RECALCULATE_LOCAL_TOTAL });
  };
}

export function setCurrentQuoteStep(currentQuoteStep) {
  return { currentQuoteStep, type: CURRENT_QUOTE_STEP_CHANGED };
}

export function paymentMethodChanged({ type, value }) {
  return { type, value };
}

export function yourInfoStepChanged(step) {
  return { step, type: YOUR_INFO_STEP_CHANGED };
}

export function createDiamondClient({
  address,
  city,
  clientId,
  email,
  firstName,
  lastName,
  phoneNumber,
  stateAbbreviation,
  stateName,
  zipCode,
}) {
  return async (dispatch) => {
    dispatch({ type: CREATE_DIAMOND_CLIENT_ATTEMPT });
    const body = {
      Address: {
        City: city,
        PetAddressLine1: address,
        PetAddressLine2: '',
        StateAbbreviation: stateAbbreviation,
        StateName: stateName,
        Zip: zipCode,
      },
      ClientId: clientId,
      FirstName: firstName,
      LastName: lastName,
      Phones: [{
        Number: phoneNumber,
        Primary: true,
      }],
      PrimaryEMail: email,
      PrimaryEMailConfirm: email,
    };
    const url = 'api/customer/saveClient';
    let response = null;
    let payload = null;

    try {
      response = await apiCall(url, { post: body, withTimeout: true });
      payload = await response.json();
      const { Data, IsValid } = payload;

      if (IsValid && Data) {
        await dispatch({
          clientId: Data.ClientId,
          type: CREATE_DIAMOND_CLIENT_SUCCESS,
        });
      } else {
        dispatch({
          message: Data || 'Error creating diamond client.',
          type: CREATE_DIAMOND_CLIENT_ERROR,
        });
        dispatch(logError({
          action: 'createDiamondClient',
          description: Data || 'Error creating diamond client.',
          endpoint: url,
          request: body,
          response: payload,
          statusCode: response.status,
        }));
      }
    } catch (exception) {
      dispatch({
        message: 'Unexpected error creating diamond client.',
        type: CREATE_DIAMOND_CLIENT_ERROR,
      });
      dispatch(logError({
        action: 'createDiamondClient',
        description: exception.toString(),
        endpoint: url,
        exception,
        request: body,
        response: payload,
        statusCode: response?.status,
      }));
    }
  };
}

export async function validateCustomerExistsByEmail(email) {
  const response = await apiCall(`api/customer/getclientinfo/${email}/`);
  const payload = await response.json();

  return payload;
}

// FIXME: i think that this is not necessary (duplicated)
export function validateCustomerExists(customer) {
  return async (dispatch) => {
    const isValidEmail = validateEmail(customer);

    if (!isValidEmail) {
      dispatch({
        message: 'Error validating customer.',
        type: CUSTOMER_VALIDATION_ERROR,
      });

      return;
    }

    dispatch({ type: CUSTOMER_VALIDATION_ATTEMPT });

    try {
      const payload = await validateCustomerExistsByEmail(customer);
      const { Data, IsValid, Message } = payload;

      if (IsValid && typeof Data === 'object') {
        await dispatch({
          customerRoles: Data.CustomerRoles,
          displayName: Data.DisplayName,
          email: Data.Email,
          fullName: Data.FullName,
          hasPassword: Data.HasPassword,
          id: Data.Id,
          type: CUSTOMER_VALIDATED,
          userName: Data.UserName,
        });
      } else {
        dispatch({
          message: Message || 'Error validating customer.',
          type: CUSTOMER_VALIDATION_ERROR,
        });
      }
    } catch (_) {
      dispatch({
        message: 'Unexpected error validating customer.',
        type: CUSTOMER_VALIDATION_ERROR,
      });
    }
  };
}

export function closeErrorModal() {
  return { type: ERROR_MODAL_CLOSED };
}

export function loadVeterinarians({ vetName, zipCode }) {
  return async (dispatch) => {
    dispatch({ type: VETERINARIANS_LOAD_ATTEMPT });
    try {
      const url = `api/Quote/Veterinarians?zipcode=${zipCode}&name=${vetName}`;
      const response = await apiCall(url);
      const payload = await response.json();

      if (payload.IsValid && response.ok) {
        dispatch({ type: VETERINARIANS_LOADED, veterinarians: payload.Data });
      } else {
        dispatch({ type: FAILED_TO_LOAD_VETERINARIANS });
      }
    } catch (_) {
      dispatch({ type: FAILED_TO_LOAD_VETERINARIANS });
    }
  };
}

export function loadAmericanStates() {
  return async (dispatch) => {
    try {
      const url = 'api/State';
      const response = await apiCall(url);
      const payload = await response.json();

      if (payload.IsValid && response.ok) {
        dispatch({
          americanStates: payload.Data,
          type: AMERICAN_STATES_LOADED,
        });
      } else {
        dispatch({ type: FAILED_TO_LOAD_AMERICAN_STATES });
      }
    } catch (_) {
      dispatch({ type: FAILED_TO_LOAD_AMERICAN_STATES });
    }
  };
}

export function saveCustomVet({ city, state, stateId, vetPractice }) {
  return { city, state, stateId, type: CUSTOM_VET_SAVED, vetPractice };
}

export function clearCustomVetData() {
  return { type: CUSTOM_VET_DATA_CLEARED };
}

export function setSelectedVeterinarian(veterinarian) {
  return { type: VETERINARIAN_SET, veterinarian };
}

export function customVeterinarianChanged({ type, value }) {
  return { type, value };
}

export function openCustomVetModal() {
  return { type: CUSTOM_VET_MODAL_OPENED };
}

export function startPurchase() {
  return { type: PURCHASE_ATTEMPT };
}

export function duplicatePurchasesValidationFailed(duplicatedPets) {
  return { data: duplicatedPets, type: DUPLICATED_VALIDATION_FAILED };
}

export function closeDuplicatedPurchasesModal() {
  return { type: DUPLICATED_VALIDATION_MODAL_CLOSED };
}

async function validateDuplicatedPurchases({
  clientId,
  pets = [],
  dispatch,
}) {
  const url = 'api/Quote/IsDuplicatedPurchase';
  let response = null;
  let petsRequest = null;
  let payload = null;
  try {
    let duplicatedPets = [];
    let notDuplicatedPets = [];
    if (!pets || pets.length === 0 || !clientId) {
      return duplicatedPets;
    }

    petsRequest = pets.map((pet) => ({
      BreedName: pet.BreedName,
      ClientId: clientId,
      IsCat: pet.IsCat,
      IsFemale: pet.IsFemale,
      PetName: pet.PetName,
    }));

    response = await apiCall(url, {
      post: petsRequest,
    });
    payload = await response.json();

    if (payload.Data) {
      // TODO: The scenario where the user is purchasing
      // two identical pets in the current Rate will be ignored
      // due missing behavior definition.
      // Those pets will be handled as not duplicated
      duplicatedPets = payload.Data
        .filter((duplicatedPet) => duplicatedPet.IsDuplicated
          && duplicatedPet.PolicyNumber);
      notDuplicatedPets = payload.Data.filter((pet) => !pet.PolicyNumber);
    }
    return {
      duplicatedPets,
      notDuplicatedPets,
    };
  } catch (error) {
    if (dispatch) {
      dispatch(logError({
        action: 'validateDuplicatedPurchases',
        description: error.toString()
          || 'Error validating duplicates',
        endpoint: url,
        exception: error,
        request: petsRequest,
        response: payload,
        statusCode: response?.status,
      }));
    }
    return [];
  }
}

const sendPurchaseErrorLog = async ({
  bodyRequest,
  response,
  message,
  status,
  email,
}) => {
  try {
    await apiCall('api/Log/PurchaseError', {
      post: {
        Email: email,
        HttpStatusCode: status,
        Message: message,
        // Not compatible with all browsers
        OperatingSystem: navigator?.userAgentData?.platform,
        request: bodyRequest,
        response,
        UserAgent: navigator.userAgent,
      },
    });
  } catch (e) {
    doNothing();
  }
};

export function purchaseQuotes({ body, clientId }) {
  return async (dispatch) => {
    let response = { ok: false };
    try {
      dispatch(startPurchase());

      const duplicatedPurchaseData = await validateDuplicatedPurchases({
        clientId,
        dispatch,
        pets: body.QuotePurchaseDto?.InsurancePetQuotes,
      });

      const { duplicatedPets } = duplicatedPurchaseData;

      if (duplicatedPets.length) {
        dispatch(duplicatePurchasesValidationFailed(duplicatedPurchaseData));
        return;
      }
      const url = `api/Quote/CompletePurchase/${clientId}`;

      response =
        await apiCall(url, { post: body, withTimeout: true });
      const payload = await response.json();
      const success = response.ok && payload.IsValid;

      if (body.IsRetryPurchase && response.status === 500) {
        dispatch({
          message: payload?.Message || RETRY_PURCHASE_ERROR,
          type: RETRY_PURCHASE_ERROR,
        });
        sendPurchaseErrorLog({
          bodyRequest: body,
          email: body.PrimaryEMail,
          message: payload?.Message || RETRY_PURCHASE_ERROR,
          response,
          statusCode: response.status,
        });
      }

      dispatch({
        customerId: payload.Data.CustomerId,
        error: success ? '' : payload.Message || payload.Data,
        incompletePurchases: payload.Data.IncompletePurchases,
        policyNumbers: payload.Data.SuccessfullyPurchased,
        success,
        type: PURCHASE_COMPLETED,
      });

      if (useFSCLeads && payload.Data.SuccessfullyPurchased.length > 0) {
        dispatch(sendFSCPurchaseLead(payload.Data.SuccessfullyPurchased));
      }
    } catch (error) {
      const { QuotePurchaseDto: { InsurancePetQuotes } } = body;
      const [pet] = InsurancePetQuotes;
      dispatch({
        error: '',
        incompletePurchases: [{
          ErrorType: PURCHASE_ERROR_TYPES.otherError,
          IsCat: pet?.IsCat,
          PaymentError: '',
          PetName: pet?.PetName,
          PetQuoteId: pet?.Id,
          TagColorId: 0,
        }],
        success: true,
        type: PURCHASE_COMPLETED,
      });

      let status = response?.status;
      if (error?.name === 'TimeoutError' || error?.name === 'AbortError') {
        status = 504;
      }
      sendPurchaseErrorLog({
        bodyRequest: body,
        email: body.PrimaryEMail,
        message: error.message || error.reason,
        response,
        status,
      });
    }
  };
}

async function purchaseMultipleQuotes({
  clientId = '',
  dispatch,
  quoteRequests = [],
  outData = {
    Data: {
      CustomerId: 0,
      IncompletePurchases: [],
      SuccessfullyPurchased: [],
    },
    HasMessages: false,
    IsValid: true,
    Message: '',
  },
}) {
  if (quoteRequests.length === 0) {
    return outData;
  }

  const [body, ...rest] = quoteRequests;
  const [quote] = body.QuotePurchaseDto.InsurancePetQuotes;
  const quoteId = quote.Id;

  dispatch({
    id: quoteId,
    status: PURCHASE_MULTI_STATUS.progress,
    type: PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED,
  });

  const url = `api/Quote/CompletePurchase/${clientId}`;
  let request = { ok: false };

  try {
    request = await apiCall(url, { post: body, withTimeout: true });
  } catch (error) {
    let status = request?.status;
    if (error?.name === 'TimeoutError' || error?.name === 'AbortError') {
      status = 504;
    }
    sendPurchaseErrorLog({
      bodyRequest: body,
      email: body.PrimaryEMail,
      message: error.message || error.reason,
      response: request,
      status,
    });
  }

  let data;

  if (request.ok) {
    const response = await request.json();
    const { Data, HasMessages, IsValid, Message } = response;

    const status = Data.SuccessfullyPurchased.length > 0
      ? PURCHASE_MULTI_STATUS.complete
      : PURCHASE_MULTI_STATUS.error;

    dispatch({
      id: quoteId,
      status,
      type: PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED,
    });

    data = {
      Data: {
        CustomerId: Data.CustomerId,
        IncompletePurchases: [
          ...outData.Data.IncompletePurchases,
          ...Data.IncompletePurchases,
        ],
        Success: Data.Success,
        SuccessfullyPurchased: [
          ...outData.Data.SuccessfullyPurchased,
          ...Data.SuccessfullyPurchased,
        ],
      },
      HasMessages: HasMessages && Message.length > 0,
      IsValid: outData.IsValid && IsValid,
      Message: `${outData.Message} ${Message}`.trim(),
    };
  } else {
    const { QuotePurchaseDto: { InsurancePetQuotes } } = body;
    const [pet] = InsurancePetQuotes;

    dispatch({
      id: quoteId,
      status: PURCHASE_MULTI_STATUS.error,
      type: PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED,
    });

    const otherIncomplete = outData?.Data?.IncompletePurchases || [];
    const otherSuccess = outData?.Data?.SuccessfullyPurchased || [];

    data = {
      Data: {
        CustomerId: outData?.Data?.CustomerId,
        IncompletePurchases: [
          ...otherIncomplete,
          {
            ErrorType: PURCHASE_ERROR_TYPES.otherError,
            IsCat: pet?.IsCat,
            PaymentError: '',
            PetName: pet?.PetName,
            PetQuoteId: pet?.Id,
            TagColorId: 0,
          },
        ],
        Success: false,
        SuccessfullyPurchased: [
          ...otherSuccess,
        ],
      },
      HasMessages: !!outData.Message,
      IsValid: false,
      Message: outData.Message,
    };
  }

  return purchaseMultipleQuotes({
    clientId,
    dispatch,
    outData: data,
    quoteRequests: rest,
  });
}

export function doPurchaseMultiple({ quoteRequests, clientId }) {
  return async (dispatch) => {
    try {
      const petQuoteList = quoteRequests.map((petQuotes) => {
        const [quote] = petQuotes.QuotePurchaseDto.InsurancePetQuotes;

        return {
          id: quote.Id,
          isCat: quote.IsCat,
          petName: quote.PetName,
          status: PURCHASE_MULTI_STATUS.pending,
        };
      });

      dispatch({ data: petQuoteList, type: PURCHASE_MULTIPLE_ATTEMPT });

      const duplicatedPurchaseData = await validateDuplicatedPurchases({
        clientId,
        dispatch,
        pets: quoteRequests
          .flatMap((quote) => quote.QuotePurchaseDto?.InsurancePetQuotes),
      });

      const { duplicatedPets } = duplicatedPurchaseData;
      if (duplicatedPets.length) {
        dispatch(duplicatePurchasesValidationFailed(duplicatedPurchaseData));
        return;
      }

      const payload = await purchaseMultipleQuotes({
        clientId,
        dispatch,
        quoteRequests,
      });

      // wait for user feedback (progress modal)
      await sleep(800);

      dispatch({
        customerId: payload.Data.CustomerId,
        error: '',
        incompletePurchases: payload.Data.IncompletePurchases,
        policyNumbers: payload.Data.SuccessfullyPurchased,
        success: true,
        type: PURCHASE_COMPLETED,
      });

      if (useFSCLeads && payload.Data.SuccessfullyPurchased.length > 0) {
        dispatch(sendFSCPurchaseLead(payload.Data.SuccessfullyPurchased));
      }
    } catch (_) {
      dispatch({ error: _, success: false, type: PURCHASE_COMPLETED });
    }
  };
}

export function termsAndConditionsChanged({ type, value }) {
  return { type, value };
}

export function validateTermsAndConditions() {
  return { type: TERMS_AND_CONDITION_VALIDATION_CHANGED };
}

export function rateSummary(post) {
  return async (dispatch) => {
    dispatch({ type: RATE_SUMMARY_ATTEMP });

    const response = await apiCall('api/Quote/GetSelectedRate', { post });
    const payload = await response.json();

    if (payload.IsValid) {
      dispatch({ data: payload.Data, type: RATE_SUMMARY_SUCCESS });
    } else {
      dispatch({ type: RATE_SUMMARY_FAILED });
    }
  };
}

export function sendSMSAppsLinks({ toPhone }) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_SMS_APPS_LINKS_ATTEMPT });
      const bodyPost = {
        body: SMS_APP_LINKS,
        toPhone,
      };

      const response = await apiCall('api/Quote/SendSMS', { post: bodyPost });
      const payload = await response.json();

      if (payload.IsValid) {
        dispatch({ type: SEND_SMS_APPS_LINKS_SUCCESS });
      } else {
        dispatch({ type: SEND_SMS_APPS_LINKS_FAILED });
      }
    } catch (_) {
      dispatch({ type: SEND_SMS_APPS_LINKS_FAILED });
    }
  };
}

export function loadMultiModalData({
  zipCode,
  isEBProduct = false,
  effectiveDate,
}) {
  return async (dispatch) => {
    const params = [zipCode, isEBProduct, effectiveDate].join('/');
    const url = `api/InsuranceMultiModal/GetAll/${params}`;
    let response = null;
    let payload = null;
    try {
      response = await apiCall(url, { withTimeout: true });
      payload = await response.json();

      if (payload.IsValid) {
        dispatch({
          data: { ...payload.Data, ZipCode: zipCode },
          type: LOAD_MULTI_MODAL_DATA_SUCCESS,
        });
      } else {
        dispatch({ type: LOAD_MULTI_MODAL_DATA_FAILED });
        dispatch(logError({
          action: 'loadMultiModalData',
          description: payload?.Message || 'Error loading multimodal data',
          endpoint: url,
          response: payload,
          statusCode: response.status,
        }));
      }
    } catch (exception) {
      dispatch({ type: LOAD_MULTI_MODAL_DATA_FAILED });
      dispatch(logError({
        action: 'loadMultiModalData',
        description: exception.toString(),
        endpoint: url,
        exception,
        response: payload,
        statusCode: response?.status,
      }));
    }
  };
}

export function multiModalCustomContenChanged({
  category = '',
  content = {},
  title = '',
  visible = false,
}) {
  return {
    category,
    content,
    title,
    type: MULTI_MODAL_CUSTOM_CONTENT_CHANGED,
    visible,
  };
}

export function multiModalCustomContenCleared() {
  return { type: MULTI_MODAL_CUSTOM_CONTENT_CLEARED };
}

export function wellnessVisibleChanged({
  categoryId = WELLNESS_DEF_CATEGORY_ID,
  visible,
}) {
  return {
    categoryId,
    type: MULTI_MODAL_WELLNESS_VISIBLE_CHANGED,
    visible,
  };
}

export function needToKnowModalVisibleChanged({
  categoryId = NEED_TO_KNOW_DEF_CATEGORY_ID,
  visible,
}) {
  return {
    categoryId,
    type: MULTI_MODAL_NEED_TO_KNOW_VISIBLE_CHANGED,
    visible,
  };
}

export function termsAndCondModalVisibleChanged({
  categoryId = TERMS_AND_COND_DEF_CATEGORY_ID,
  visible,
}) {
  return {
    categoryId,
    type: MULTI_MODAL_TERMS_AND_COND_VISIBLE_CHANGED,
    visible,
  };
}

export function petCoPayModalVisibleChanged({
  categoryId = PET_CO_PAY_DEF_CATEGORY_ID,
  visible,
}) {
  return {
    categoryId,
    type: MULTI_MODAL_PET_CO_PAY_VISIBLE_CHANGED,
    visible,
  };
}

export function togglePetCoPayModalVisibleChanged({ visible }) {
  return {
    type: MODAL_PET_CO_PAY_VISIBLE_CHANGED,
    visible,
  };
}

export function resetPurchaseData() {
  return { error: '', success: false, type: PURCHASE_COMPLETED };
}

export function powerUpSelected({ id, isSelected = null, quoteId }) {
  return { id, isSelected, quoteId, type: POWER_UP_SELECTED };
}

export function forgetPasswordEmailChanged({ email }) {
  return { email, type: FORGET_PASSWORD_MODAL_EMAIL_CHANGED };
}

export function forgetPasswordEmailErrorChanged({ error }) {
  return { error, type: FORGET_PASSWORD_MODAL_EMAIL_ERROR_CHANGED };
}

export function forgetPasswordVisibleChanged({ visible }) {
  return { type: FORGET_PASSWORD_MODAL_VISIBLE_CHANGED, visible };
}

export function forgetPasswordConfirmVisibleChanged({ visible }) {
  return { type: FORGET_PASSWORD_CONFIRM_MODAL_VISIBLE_CHANGED, visible };
}

export function resetPassword({ email }) {
  return async (dispatch) => {
    dispatch({ type: FORGET_PASSWORD_RESET_PASSWORD_ATTEMTPED });

    try {
      const resetPasswordURL = 'api/Account/PasswordRecoveryMessage';
      const url = `${window.location.origin}/`;

      const bodyRequest = {
        Email: email,
        Url: url,
      };

      const resetPasswordResponse =
        await apiCall(resetPasswordURL, { post: bodyRequest });
      const resetPasswordPayload = await resetPasswordResponse.json();

      if (resetPasswordResponse.ok && resetPasswordPayload.IsValid) {
        dispatch({ type: FORGET_RESET_PASSWORD_SUCCEEDED });
      } else {
        dispatch({
          message: resetPasswordPayload.Message || 'Error on reset password.',
          type: FORGET_RESET_PASSWORD_FAILED,
        });
      }
    } catch (_) {
      dispatch({
        message: 'Unexpected error.',
        type: FORGET_RESET_PASSWORD_FAILED,
      });
    }
  };
}

export function forceValdationDiamonUser(email) {
  return {
    isCustomer: true,
    type: DIAMOND_CUSTOMER_VALIDATION_LOADED,
    userEmail: email,
  };
}

export function validateDiamondUser({
  customerExistsData = null,
  email = '',
  userAlreadyExists = false,
  validateCustomer = true,
}) {
  return async (dispatch) => {
    try {
      const isValidEmail = validateEmail(email);

      if (!isValidEmail) {
        dispatch(newCustomerChanged({
          type: NEW_CUSTOMER_EMAIL_ERROR_CHANGED,
          value: 'Invalid email address',
        }));
        return;
      }

      dispatch({ type: DIAMOND_CUSTOMER_VALIDATION_ATTEMPT });

      let isCustomer = userAlreadyExists;
      let customerInfo = customerExistsData;
      let hasCustomerRoll = false;
      let id = 0;

      if (validateCustomer) {
        const { IsValid, Data } = await validateCustomerExistsByEmail(email);
        isCustomer = IsValid && typeof Data === 'object';
        customerInfo = Data;
      }

      if (isCustomer) {
        id = customerInfo.Id; // FIXME: Data.Id allways is 0
        hasCustomerRoll = !!customerInfo.CustomerRoles
          .find((roll) => USER_ROLL_LIST.includes(roll));

        await dispatch({
          customerRoles: customerInfo.CustomerRoles,
          displayName: customerInfo.DisplayName,
          email: customerInfo.Email,
          fullName: customerInfo.FullName,
          hasPassword: customerInfo.HasPassword,
          id,
          type: CUSTOMER_VALIDATED,
          userName: customerInfo.UserName,
        });
      }

      if (isCustomer && !hasCustomerRoll) {
        if (useMarketingChannel) {
          return;
        }
        // TODO: delete after marketing channel on prod
        // users with no roll or diamondCliendId: 0 need call customer service.
        // WPI: Petclout Free user?
        dispatch({ type: DIAMOND_CUSTOMER_VALIDATION_FAILED });
        dispatch(noDiamondClientIdError('User without customer roll'));
        return;
      }

      if (!isCustomer) {
        dispatch({ email, type: CUSTOMER_VALIDATION_RESTARTED });
      }

      dispatch({
        id,
        isCustomer,
        type: DIAMOND_CUSTOMER_VALIDATION_LOADED,
        userEmail: email,
      });
    } catch (exception) {
      dispatch({ type: DIAMOND_CUSTOMER_VALIDATION_FAILED });
      dispatch(noDiamondClientIdError(`An error ocurred ${exception.message}`));
      dispatch(logError({
        action: 'validateDiamondUser',
        description: exception.toString(),
        endpoint: '',
        exception,
        statusCode: '',
      }));
    }
  };
}

async function saveOneIncPaymentMethod({ data, dispatch }) {
  const billingInfoList = data
    .filter((item) => item.PaymentProfileId)
    .map((item) => ({
      customerName:
        `${item.AddressFirstName || ''} ${item.LastName || ''}`,
      id: item.PaymentProfileId,
      lastFourDigits: item.BillingInfoType === 1
        ? item.MaskedCardNumber && item.MaskedCardNumber.substring(4)
        : item.MaskedAccountNumber && item.MaskedAccountNumber.substring(4),
      paymentCategory: item.BillingInfoType === 1
        ? ONE_INC_PAYMENT_CATEGORY.creditCard
        : ONE_INC_PAYMENT_CATEGORY.bankAccount,
      tokenId: ONE_INC_DEFAULT_TOKEN,
    }));

  if (billingInfoList.length > 1) {
    const [bankAccount, creditCard] = billingInfoList;
    const oneIncData = {
      bankAccount,
      creditCard,
      isTwoDefaults: true,
      paymentCategory: ONE_INC_PAYMENT_CATEGORY.bankAccount,
      tokenId: ONE_INC_DEFAULT_TOKEN,
    };

    dispatch(savePaymentMethods(oneIncData));
    dispatch(savePaymentDefaultMethods({
      bankAccount,
      creditCard,
      defaultValue: ONE_INC_PAYMENT_CATEGORY.bankAccount,
    }));
  } else {
    const payment = billingInfoList && billingInfoList[0];
    const isCreditCard =
      payment.paymentCategory === ONE_INC_PAYMENT_CATEGORY.creditCard;

    dispatch(savePaymentMethod(payment));
    dispatch(savePaymentDefaultMethods({
      bankAccount: !isCreditCard && payment,
      creditCard: isCreditCard && payment,
      defaultValue: payment.paymentCategory,
    }));
  }
}

export function onLoginB2CROPC() {
  return { type: LOGIN_B2C_STARTED };
}

export function onLoginB2CROPCFailed() {
  return { type: LOGIN_B2C_FAILED };
}

export function onLoginDiamondUser({ email, password, b2cToken }) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DIAMOND_CUSTOMER_LOGIN_ATTEMPT });

      const headers = b2cToken ? {
        Authorization: `Bearer ${b2cToken}`,
      } : '';

      const response = await apiCall('api/Customer/LogInEBOnNopCommerce', {
        headers,
        post: {
          email,
          password,
        },
      });
      const payload = await response.json();
      const { Data, IsValid } = payload;

      const isCostco = useCostcoTN && getState().quoting.partners.isCostco;
      const isGoodDog = useGoodDogTN && getState().quoting.partners.isGoodDog;

      const needValidateZipCode = isCostco || isGoodDog;

      if (needValidateZipCode && Data.Address && Data.Address.ZipCode) {
        const { Address: { ZipCode } } = Data;

        const isZipCodeValid =
          await dispatch(validateZipCodeComingSoon({
            email,
            loadAddress: false,
            zipcode: ZipCode,
          }));

        if (!isZipCodeValid) {
          dispatch({
            message: '',
            type: DIAMOND_CUSTOMER_LOGIN_FAILED,
          });

          return;
        }
      }

      const {
        Address,
        BillingInfoList,
        CanEditAddress,
        CustomerNopCommerceId,
        DefaultPaymentProfileIdACH,
        DefaultPaymentProfileIdCC,
        DiamonClientdId,
        FirstName,
        LastName,
        LoginMessage,
        LoginSuccess,
        MarketingChannelId,
        MiddleName,
        PetPaymentMethod,
      } = Data;
      const data = {
        Address: {
          AddressLine1: (Address && Address.AddressLine1) || '',
          AddressLine2: (Address && Address.AddressLine2) || '',
          City: (Address && Address.City) || '',
          State: (Address && Address.State) || '',
          ZipCode: (Address && Address.ZipCode) || '',
        },
        BillingInfoList,
        CanEditAddress,
        CustomerNopCommerceId,
        DefaultPaymentProfileIdACH,
        DefaultPaymentProfileIdCC,
        DiamonClientdId,
        FirstName: FirstName || '',
        LastName: LastName || '',
        LoginMessage,
        LoginSuccess,
        MarketingChannelId: MarketingChannelId || 0,
        MiddleName: MiddleName || '',
      };

      const defaultPaymentMethod =
        DefaultPaymentProfileIdACH || DefaultPaymentProfileIdCC;
      const isCreditCard = !DefaultPaymentProfileIdACH;

      dispatch(saveOneIncPetPaymentMethod(PetPaymentMethod));

      if (defaultPaymentMethod) {
        await saveOneIncPaymentMethod({
          data: BillingInfoList,
          DefaultPaymentProfileIdACH,
          dispatch,
        });

        dispatch(paymentMethodChanged({
          type: PAYMENT_METHOD_TYPE_CHANGE,
          value: isCreditCard
            ? PAYMENT_TYPE.creditCard
            : PAYMENT_TYPE.bank,
        }));
      }

      if (IsValid && LoginSuccess) {
        dispatch({
          data,
          email,
          password,
          type: DIAMOND_CUSTOMER_LOGIN_SUCCESS,
          zipCode: data.Address.ZipCode,
        });
      } else {
        dispatch({
          message: LoginMessage || '',
          type: DIAMOND_CUSTOMER_LOGIN_FAILED,
        });
      }
    } catch (_) {
      dispatch({ type: DIAMOND_CUSTOMER_LOGIN_FAILED });
    }
  };
}

export function hideCustomerWelcomeMessage() {
  return { type: DIAMOND_CUSTOMER_LOGIN_FAILED };
}

export function deleteSession() {
  return { type: DIAMOND_CUSTOMER_LOGOUT };
}

export function reRateByNopCommerCustomer({
  diamondClientId,
  ebGuID,
  effectiveDateCustom,
  eMail,
  employerName,
  nopCommerceClientId,
  parameters,
  petQuoteResponseList,
  promoCode,
  subId,
  zipCode,
}) {
  return async (dispatch) => {
    try {
      const body = {
        DiamondClientId: diamondClientId,
        ebGuID,
        EffectiveDate: null,
        eMail,
        employerName,
        GroupCode: promoCode,
        GroupCodeDscr: parameters.groupCodeDscr,
        nopCommerceClientId,
        petQuotes: [
          ...petQuoteResponseList.map((quote) => ({
            cloudOrderId: quote.cloudOrderId,
            Id: quote.petQuoteId,
            Modifiers: quote.modifiers,
            PetAge: quote.petAgeName,
            PetBreed: quote.breedName,
            PetBreedId: quote.breedId,
            PetName: quote.petName,
            PetSex: quote.genderName,
            PetType: quote.petType,
          })),
        ],
        quoteId: petQuoteResponseList[0].petQuoteId,
        QuoteSubId: subId,
        TestEffectiveDate: effectiveDateCustom,
        ZipCode: zipCode,
      };

      await dispatch(rateQuote({
        body,
        showLoading: true,
        tag: AFTER_RATE_TAG.newZipCode,
        update: true,
      }));
      dispatch({ type: NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL });
      dispatch({ type: DIAMOND_CUSTOMER_ZIPCODE_CHANGED, zipCode });
    } catch (_) {
      doNothing();
    }
  };
}

export function resetLoginFailMessage() {
  return { type: DIAMOND_CUSTOMER_RESETED_LOGIN_FAIL };
}

export function diamondClientEmailClean() {
  return { type: DIAMOND_CUSTOMER_LOGIN_CLEANED };
}

export function modalCustomerWelcomeVisibleChanged(visible) {
  return { type: MODAL_CUSTOMER_WELCOME_VISIBLE_CHANGED, visible };
}
/**
 * @param {boolean} obj.impersonate
 * @param {string} obj.impersonatingAdminEmail
 * @param {boolean} obj.inApp
 * @param {boolean} obj.isCostco quote created from petcloud with costco
 * @param {string} obj.orderId
 * @param {string} obj.publicToken
 */
export function loadQuoteDataByOrderId({
  impersonate = false,
  impersonatingAdminEmail = '',
  inApp,
  isCostco = false,
  orderId,
  publicToken,
}) {
  return async (dispatch) => {
    dispatch({ type: LOADING_QUOTE_DATA });

    if (inApp) {
      dispatch({ type: IN_APP_STARTED });
    }

    dispatch({
      impersonatingEmail: impersonatingAdminEmail,
      isImpersonating: impersonate,
      type: IMPERSONATING_INFO_CHANGED,
    });

    let rateUrl = `api/Quote/GetRate?orderId=${orderId}`;

    if (isCostco) {
      rateUrl = `${rateUrl}&partner=${COSTCO_PARTNER_ID}`;
      await dispatch(partnerIdLoaded(COSTCO_PARTNER_ID));
    }

    const loginUrl = 'api/Customer/'
      + `LogInOnNopCommerceByPublicToken/${publicToken}`;
    let urlToLog = rateUrl;
    let statusToLog = '';
    let payloadToLog = null;
    try {
      const rateQuoteResponse =
        await apiCall(rateUrl, { withTimeout: true });
      const payloadRateQuote = await rateQuoteResponse.json();

      const loginResponse = await apiCall(loginUrl, {
        post: {},
        withTimeout: true,
      });

      if (loginResponse.status !== 200) {
        statusToLog = loginResponse.status;
        urlToLog = loginUrl;
      }
      const loginData = await loginResponse.json();
      payloadToLog = loginData;

      if (loginData.IsValid) {
        const {
          Address,
          BillingInfoList,
          DefaultPaymentProfileIdACH,
          DefaultPaymentProfileIdCC,
          PetPaymentMethod,
          CustomerEmail,
        } = loginData.Data;

        const defaultPaymentMethod =
          DefaultPaymentProfileIdACH || DefaultPaymentProfileIdCC;
        const isCreditCard = !DefaultPaymentProfileIdACH;

        dispatch(saveOneIncPetPaymentMethod(PetPaymentMethod));

        if (defaultPaymentMethod) {
          await saveOneIncPaymentMethod({
            data: BillingInfoList,
            DefaultPaymentProfileIdACH,
            dispatch,
          });

          dispatch(paymentMethodChanged({
            type: PAYMENT_METHOD_TYPE_CHANGE,
            value: isCreditCard
              ? PAYMENT_TYPE.creditCard
              : PAYMENT_TYPE.bank,
          }));
        }

        dispatch({
          data: loginData.Data,
          email: CustomerEmail,
          password: '',
          type: DIAMOND_CUSTOMER_LOGIN_SUCCESS,
          zipCode: Address.ZipCode,
        });

        dispatch({
          parameters: { petZipCode: Address.ZipCode },
          type: PARAMETERS_UPDATED,
        });

        dispatch({ type: NEW_PET_ZIPCODE_SAVED, zipCode: Address.ZipCode });
      }
      payloadToLog = payloadRateQuote;

      if (payloadRateQuote.IsValid) {
        const { Data } = payloadRateQuote;
        const { petQuoteResponseList } = Data;

        petQuoteResponseList.forEach(async (petQuote) => {
          const rateDate = await makeRateData({ Data, petQuote });

          dispatch({ ...rateDate });
        });

        if (petQuoteResponseList.length > 0) {
          dispatch(saveSelectedPetNumber(petQuoteResponseList[0].petQuoteId));
        }

        await sleep(300);
      } else {
        dispatch(logError({
          action: 'loadQuoteDataByOrderId',
          description: payloadRateQuote.Message
            || 'Error rating quote.',
          endpoint: rateUrl,
          response: payloadRateQuote,
          statusCode: rateQuoteResponse.status,
        }));
        dispatch({
          message: payloadRateQuote.Message
            || 'Error rating quote.',
          redirectToLanding: true,
          type: RATE_QUOTE_ERROR,
        });
      }
    } catch (exception) {
      dispatch(logError({
        action: 'loadQuoteDataByOrderId',
        description: exception.toString(),
        endpoint: urlToLog,
        exception,
        response: payloadToLog,
        statusCode: statusToLog,
      }));
      dispatch({
        message: 'Unexpected error rating quote.',
        type: RATE_QUOTE_ERROR,
      });
    }

    dispatch({
      tag: AFTER_RATE_TAG.loadByOrderId,
      type: LOADING_QUOTE_DATA_FINISH,
    });
  };
}

export function extoleQuoteUUIDSave(payload) {
  return { payload, type: EXTOLE_UUID_SAVED };
}

export function extolePurchaseCompleted(payload) {
  return { payload, type: EXTOLE_PURCHASE_COMPLETED };
}

export function effectiveDateCustomChanged(newDate) {
  return { newDate, type: EFFECTIVE_DATE_CUSTOM_CHANGED };
}

export function saveUtmParams(payload) {
  return { payload, type: SAVE_UTM_PARAMS };
}

export function inAppStarted() {
  return { type: IN_LOGIN_APP_STARTED };
}

export function resetTagAfterRate() {
  return { tag: '', type: LOADING_QUOTE_DATA_FINISH };
}

export function SMSSignedUp({
  phoneNumber,
  diamondClientId,
  customerId,
  email,
  policyNumbers,
}) {
  return async (dispatch) => {
    dispatch({ type: SIGNED_UP_SMS_ATTEMPT });

    try {
      const url = 'api/Customer/SaveClientSmsConsent';
      const response = await apiCall(url, {
        post: {
          customerId,
          diamondClientId,
          email,
          policyNumbers,
          smsphone: phoneNumber,
        },
      });
      const payload = await response.json();
      dispatch({
        error: payload.Message,
        success: payload.IsValid && payload.Data,
        type: SIGNED_UP_SMS,
      });

      if (useFSCLeads && payload.IsValid) {
        dispatch(sendFSCMarketingLead({ phoneNumber }));
      }
    } catch (error) {
      dispatch({
        error,
        success: false,
        type: SIGNED_UP_SMS,
      });
    }
  };
}

export function removeQuoteSelectedValueById(petQuoteId) {
  return { petQuoteId, type: QUOTE_SELECTED_VALUES_REMOVED };
}

export function changeGoodDogComingSoonModal(visible) {
  const type = visible
    ? GOOD_DOG_COMING_SOON_MODAL_SHOWED
    : GOOD_DOG_COMING_SOON_MODAL_HIDED;

  return { type };
}

export function validateRecaptchaToken({ origin, token, user }) {
  return async (dispatch) => {
    dispatch({ type: RECAPTCHA_VALIDATE_ATTEMPT });
    let isTokenValid = false;
    const legacyAPI = 'api/Recaptcha/Siteverify';
    const purchaseAPI = 'purchase/Recaptcha/Siteverify';
    const { QuoteSiteD2CPurchaseV1T159710 } = getFeatureFlagFromStorage();
    const url = QuoteSiteD2CPurchaseV1T159710 ? purchaseAPI : legacyAPI;
    let response = null;
    let payload = null;
    let post = null;

    try {
      post = {
        Email: user,
        Origin: origin,
        Token: token,
      };
      response = await apiCall(url, {
        post,
        withTimeout: true,
      });
      payload = await response.json();

      if (response.ok && payload.IsValid) {
        isTokenValid = true;
        dispatch({ type: RECAPTCHA_VALIDATE_LOADED });
      } else {
        dispatch({ error: payload.Message, type: RECAPTCHA_VALIDATE_FAILED });
        dispatch({
          message: `reCAPTCHA ${payload.Message}`,
          type: RATE_QUOTE_ERROR,
        });
        dispatch(logError({
          action: 'validateRecaptchaToken',
          description: `reCAPTCHA ${payload.Message}`,
          endpoint: url,
          request: post,
          response: payload,
          statusCode: response.status,
        }));
      }
    } catch (error) {
      dispatch({ error: error.message, type: RECAPTCHA_VALIDATE_FAILED });
      dispatch({
        message: `reCAPTCHA ${error.message}`,
        type: RATE_QUOTE_ERROR,
      });
      dispatch(logError({
        action: 'validateRecaptchaToken',
        description: `reCAPTCHA ${error.toString()}`,
        endpoint: url,
        exception: error,
        request: post,
        response: payload,
        statusCode: response?.status,
      }));
    }

    return isTokenValid;
  };
}

export function changeTermsAndPolicyCheckbox(newStatus) {
  return { newStatus, type: TERMS_AND_POLICY_CHANGED };
}

export function setPowerUpErrors(modifierIdList) {
  return { modifierIds: modifierIdList, type: POWERUP_ID_ERRORS_SET };
}

export function removePowerupIdError(modifierId) {
  return { modifierId, type: POWERUP_ID_ERRORS_REMOVED };
}

export function restartPowerupErrors() {
  return { type: POWERUP_ID_ERRORS_RESTARTED };
}

export function setAsHasPassword(value = true) {
  return { type: CUSTOMER_PASSWORD_SET, value };
}
