import { doNothing, getSessionStorage, setSessionStorage } from '../util';

const KEY_FEATURE_FLAGS = 'quoting.d2c.flags';

const FEATURE_FLAGS_LOADED_EVENT_NAME = 'featureFlagsLoaded';

const storeFeatureFlags = (featureFlags = { }) => {
  try {
    setSessionStorage({
      data: featureFlags,
      key: KEY_FEATURE_FLAGS,
    });
    window.dispatchEvent(new Event(FEATURE_FLAGS_LOADED_EVENT_NAME));
  } catch (_) {
    doNothing();
  }
};

const getFeatureFlagFromStorage = () => {
  let featureFlags = {};

  try {
    featureFlags = getSessionStorage(KEY_FEATURE_FLAGS) || { };
  } catch (_) {
    doNothing();
  }

  return featureFlags;
};

const featureFlagsLoadedValidation = () => {
  try {
    const featureFlags = getSessionStorage(KEY_FEATURE_FLAGS);

    return !!featureFlags;
  } catch {
    return false;
  }
};

export {
  FEATURE_FLAGS_LOADED_EVENT_NAME,
  featureFlagsLoadedValidation,
  getFeatureFlagFromStorage,
  storeFeatureFlags,
};
